export const formatPrice = (value, priceSettings = {}) => {
  const {
    currencySymbol = `$`,
    commaDecimalPoint,
    currencySymbolToEnd,
    addSpaceCurrencySymbol
  } = priceSettings;
  let amount = parseFloat(value).toFixed(2);

  if (commaDecimalPoint) {
    amount = amount.replace('.', ',');
  }

  const space = addSpaceCurrencySymbol ? ' ' : '';

  if (currencySymbolToEnd) {
    amount = `${amount}${space}${currencySymbol}`;
  } else {
    amount = `${currencySymbol}${space}${amount}`;
  }
  return amount;
};
