import { Builder } from '@builder.io/react';
import ProductSelector from './ProductSelector';

Builder.registerComponent(ProductSelector, {
  name: 'Product Selector',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: [
        'version1',
        'version2',
        'version2SubOnly',
        'version3',
        'version4',
        'version5'
      ],
      defaultValue: 'version1'
    },
    {
      name: 'OTPAppropriateSubVariant',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'faqURL',
      type: 'string',
      defaultValue:
        'https://thepetlabco.com/pages/our-most-frequently-asked-questions'
    },
    {
      name: 'tubs',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: '1 Tub',
          required: true
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
        },
        {
          name: 'image2',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
        },
        {
          name: 'showPrice',
          type: 'boolean',
          defaultValue: false,
          required: false
        }
      ],
      showIf: options =>
        options.get('designVersion') === 'version2' ||
        'version2SubOnly' ||
        'version4' ||
        'version5'
    },
    {
      name: 'subscriptionSchedules',
      type: 'list',
      subFields: [
        {
          name: 'id',
          type: 'string',
          defaultValue: '1:month',
          required: true
        },
        {
          name: 'label',
          type: 'string',
          defaultValue: 'Month (Most Common)',
          required: true
        }
      ],
      showIf: options =>
        options.get('designVersion') === 'version2' ||
        'version2SubOnly' ||
        'version4' ||
        'version5'
    },

    // version 3
    {
      name: 'boxes',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: '1 month supply',
          required: false
        },
        {
          name: 'description',
          type: 'string',
          defaultValue: '1 month supply',
          required: false
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: false,
          defaultValue: ''
        },
        // {
        //   name: "regular_price",
        //   type: "string",
        //   defaultValue: "",
        //   required: false,
        // },
        // {
        //   name: "discounted_price",
        //   type: "string",
        //   defaultValue: "",
        //   required: false,
        // },
        {
          name: 'button_text',
          type: 'string',
          defaultValue: 'Add to Cart',
          required: false
        },
        {
          name: 'best_value',
          type: 'boolean',
          defaultValue: false,
          required: false
        },
        {
          name: 'free_shipping',
          type: 'boolean',
          defaultValue: true,
          required: false
        }
      ],
      showIf: options => options.get('designVersion') === 'version3'
    },
    {
      name: 'products_list',
      type: 'object',
      required: true,
      showIf: options => options.get('designVersion') === 'version3'
    },
    //version5
    {
      name: 'preorder_text',
      type: 'string',
      defaultValue: 'Your order will be shipped on 1st - 4th, June',
      showIf: options => options.get('designVersion') === 'version5'
    },
    {
      name: 'preorder_image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'png'],
      showIf: options => options.get('designVersion') === 'version5'
    },
    {
      name: 'hideBumpOfferDiscount',
      type: 'boolean',
      defaultValue: false,
      required: false,
      showIf: options =>
        options.get('designVersion') === 'version1' ||
        'version2' ||
        'version4' ||
        'version5'
    },
    {
      name: 'bumpOfferPrefixText',
      type: 'string',
      defaultValue: 'Enhance Your Chews With Our',
      required: false
    }
  ]
});
