import Cookies from 'universal-cookie';
import { getParamFromQuery } from './getParamFromQuery';

const cookies = new Cookies();

//get path from gtm cookie for tracking object that gets passed to checkout API below
export const getPathFromGtm = (gtmCookie, gtmObj) => {
  if (!gtmCookie) {
    return '';
  }
  //remove params if any
  const noParams = gtmCookie.split('?')[0];
  //remove trailing slash if any
  const trailingSlash = noParams.replace(/\/$/, '');
  //get last segment
  const lastSegment = trailingSlash.substring(
    trailingSlash.lastIndexOf('/') + 1
  );
  //combine last segment with underscore and id from gtm cookie url
  return lastSegment + '_' + gtmObj[Object.keys(gtmObj)[0]];
};

export const gaCookie = cookies.get('_ga');
export const gtmCookie = cookies.get('__gtm_campaign_url');
export const gtmObj = Object.fromEntries(new URLSearchParams(gtmCookie));
export const pathFromGtm = getPathFromGtm(gtmCookie, gtmObj);
export const splitTest = cookies.get('split_test');

// make checkout request
export const utmTracking = path => {
  let trackingPath = path;

  if (gtmCookie) {
    trackingPath = `${path}-${pathFromGtm}`;
  }

  // add split test
  if (splitTest) {
    trackingPath = `${path}-${splitTest}`;
  }

  return {
    _ga: gaCookie || '',
    id: pathFromGtm || getParamFromQuery('id'),
    path: trackingPath,
    utm_medium: gtmObj.utm_medium || getParamFromQuery('utm_medium'),
    utm_source: gtmObj.utm_source || getParamFromQuery('utm_source'),
    utm_campaign: gtmObj.utm_campaign || getParamFromQuery('utm_campaign'),
    variation: getParamFromQuery('variation') || '',
    h_ad_id: getParamFromQuery('h_ad_id') || ''
  };
};

export default utmTracking;
