import React, { useContext, useState } from 'react';
import * as S from './UserPaymentMethodTryAgain.styles';
import FunnelContext from '../../context/FunnelContext';
import { Builder } from '@builder.io/react';
import { updatePaymentMethod2 } from '../../utils/user';
import utmTracking from '../../utils/utmTracking';
import { addDataLayer } from '../../utils/dataLayer';
import { requestPaymentToken } from '../../utils/requestPaymentToken';

const UserPaymentMethodTryAgainButton = props => {
  const failEvent = 'magic_link_payment_method_update_failed';
  const successEvent = 'magic_link_payment_method_update_successful';
  const [ccButtonClicked, setCcButtonClicked] = useState(false);
  const { extraObjects, setCurrentLayer } = useContext(FunnelContext);

  const triggerCCUpdate = () => {
    if (Builder.isEditing) return;
    if (ccButtonClicked) return;
    setCcButtonClicked(true);

    requestPaymentToken({
      sandbox: extraObjects?._isCheckoutComSandbox,
      cc_number: extraObjects?.ccn,
      expiry_month: extraObjects?.exp.split('/')[0],
      expiry_year: extraObjects?.exp.split('/')[1],
      cvv: extraObjects?.cvv,
      type: 'card',
      storeGeo: extraObjects?.user.storeGeo
    })
      .then(({ data }) => {
        updatePaymentMethod2({
          bearerToken: extraObjects?.authToken,
          checkoutToken: data.token,
          paymentGateway: data.gateway,
          utmTracking: utmTracking(window.location.pathname)
        })
          .then(() => {
            setCcButtonClicked(false);
            addDataLayer(successEvent, {
              payment_method: 'Credit Card',
              sub_db_user_id: extraObjects?.user.id
            });
            setCurrentLayer('success');
          })
          .catch(() => {
            setCcButtonClicked(false);
            addDataLayer(failEvent, {
              payment_method: 'Credit Card',
              sub_db_user_id: extraObjects?.user.id
            });
            setCurrentLayer('failed');
          });
      })
      .catch(() => {
        setCcButtonClicked(false);
      });
  };

  return (
    <S.TryAgainButton onClick={triggerCCUpdate} buttonClicked={ccButtonClicked}>
      <span>Try again</span>
    </S.TryAgainButton>
  );
};

export default UserPaymentMethodTryAgainButton;
