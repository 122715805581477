import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const FunnelText = loadable(() => import('./FunnelText'));

Builder.registerComponent(FunnelText, {
  name: 'Funnel Text',
  noWrap: true,
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'text',
      type: 'richText',
      defaultValue: '[currency-symbol][savings][discounted-price][price]',
      helperText: `template tags: [currency-symbol] [savings] [discounted-price] [price] 
      [image] [quantity] [bump-discounted-price] [bump-price] [bump-image]
      [price-per-chew] [discounted-price-per-chew] [price-per-tub] [discounted-price-per-tub]
      [extra-firstname], [extra-{custom key}]`
    },
    {
      name: 'margin',
      type: 'string'
    },
    {
      name: 'chewsPerTub',
      type: 'number',
      defaultValue: 30,
      advanced: true
    },
    {
      name: 'savingsAddon',
      type: 'number',
      defaultValue: 0,
      advanced: true
    },
    {
      name: 'subSavingsAddon',
      type: 'number',
      defaultValue: 0,
      advanced: true
    },
    {
      name: 'otpSavingsAddon',
      type: 'number',
      defaultValue: 0,
      advanced: true
    },
    {
      name: 'bumpOfferIndex',
      type: 'number',
      defaultValue: 0,
      advanced: true
    }
  ]
});
