import React from 'react';
import UpsellPopupV1 from './UpsellPopupV1';
import UpsellPopupV2 from './UpsellPopupV2';

const UpsellPopup = props => {
  const { designVersion } = props;

  switch (designVersion) {
    case 'version2':
      return <UpsellPopupV2 {...props} />;
    default:
    case 'version1':
      return <UpsellPopupV1 {...props} />;
  }
};

export default UpsellPopup;
