import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const TagSelector = loadable(() => import('./TagSelector'));

Builder.registerComponent(withChildren(TagSelector), {
  name: 'Tag Selector',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'styles',
      type: 'string',
      enum: ['default', 'pdp2023', 'blue'],
      defaultValue: 'default'
    },
    {
      name: 'container',
      type: 'list',
      subFields: [
        {
          name: 'tag',
          type: 'string',
          defaultValue: 'tag',
          required: true
        },
        {
          name: 'default',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'setCurrentTubIndex',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'currentTubIndex',
          type: 'number',
          defaultValue: 0
        },
        {
          name: 'setRecommendedTub',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'recommendedTubIndex',
          type: 'number',
          defaultValue: 0
        },
        {
          name: 'recommendedTubText',
          type: 'text',
          defaultValue: '1 Tub (Recommended)'
        },
        {
          name: 'answers',
          type: 'list',
          subFields: [
            {
              name: 'key',
              type: 'string',
              required: true
            },
            {
              name: 'value',
              type: 'string',
              required: false
            }
          ],
          advanced: true
        }
      ]
    }
  ],
  withChildren: true
});
