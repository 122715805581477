import styled, { keyframes, css } from 'styled-components';

const slideInAnimation = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOutAnimation = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 650px) {
    align-items: flex-end;
  }
`;

export const Container = styled.div`
  z-index: 9999;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 16px;
  position: relative;
  margin: auto;

  @media (max-width: 650px) {
    position: fixed;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    border-radius: 16px 16px 0 0;

    ${({ closing }) => {
      if (closing === null) {
        return ``;
      }
      return closing
        ? css`
            animation: ${slideOutAnimation} 0.5s ease-out forwards;
          `
        : css`
            animation: ${slideInAnimation} 0.5s ease-out forwards;
          `;
    }}
  }
`;
export const PopupHeader = styled.div`
  font-family: 'Neuzeit Grotesk', sans-serif;
  color: #001c72;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 16px 16px 0 0;
  @media (max-width: 650px) {
    padding: 24px 16px;
  }
`;

export const PopupTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PopupTitle = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
`;

export const PopupHeaderBody = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const ProductImage = styled.div`
  max-width: 72px;
  max-height: 72px;
  padding: 8px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  position: relative;
  background: linear-gradient(to bottom, #e8e8e8 65%, #f2f2f2 35%);

  img {
    width: 90%;
  }
`;

export const QuantityCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #001c72;
  color: #ffffff;
  opacity: 0.8;
  font-weight: 700;
  font-size: 16px;
`;

export const AddedProductTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  align-self: center;
`;

export const AddedProductTitle = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
`;

export const AddedProductSubTitle = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6677aa;
`;

export const AddedProductPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-items: flex-end;
  align-self: center;
`;

export const AddedProductPrice = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6677aa;
  text-decoration: line-through;
`;

export const AddedProductDiscountPrice = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`;

export const Body = styled.div`
  font-family: 'Neuzeit Grotesk', sans-serif;
  border-radius: 0 0 16px 16px;
  padding: 14px 14px 0 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #001c72;
  background-color: #f2f4f8;

  @media (max-width: 650px) {
    padding-inline: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  font-family: 'Neuzeit Grotesk', sans-serif;

  @media (max-width: 950px) {
  }

  @media (max-width: 650px) {
    margin-inline: 8px;
  }
`;
export const CloseButton = styled.span`
  color: #001c72;
  border-radius: 100%;
  cursor: pointer;
  font-size: 40px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 700;

  @media (max-width: 650px) {
    padding-right: 8px;
  }
`;

export const AcceptButton = styled.div`
  background: #cd0053;
  padding: 18px 50px;
  border-radius: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-left: 17px;
  width: 250px;

  @media (max-width: 650px) {
    width: 150px;
    padding: 18px 15px;
  }
`;
export const DeclineButton = styled.div`
  border-radius: 80px;
  border: solid 2px #cd0053;
  cursor: pointer;
  padding: 18px 50px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cd0053;
  width: 250px;

  @media (max-width: 650px) {
    width: 150px;
    padding: 18px 30px;
  }
`;
