import styled from 'styled-components';

export const TryAgainButton = styled.button`
  border-radius: 40px;
  background-color: #bc2e3e;
  text-align: center;
  font-size: 20px;
  padding: 16px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  border: none;
  outline: none;
  color: #fff;
  font-weight: 700;
  line-height: normal;
  margin-top: 16px;
  cursor: pointer;

  &:hover {
    background-color: #a02734;
  }

  ${({ buttonClicked }) => {
    return buttonClicked
      ? `
    &:before {
      content: "";
      height: 22px;
      width: 100%;
      background-image: url("/images/three-dots.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
    }
    & > span {
      display: none;
    }
   
    background-color: #a02734;
    cursor: initial;
      
    `
      : ``;
  }}
`;
