import React, { useEffect, useState } from 'react';
import * as S from './UrgencyTag.styles';

export const UrgencyTag = props => {
  const { urgencyTagIcon, urgencyTagText, withAnimation = false } = props;

  const [showUrgencyTag, setShowUrgencyTag] = useState(!withAnimation);

  useEffect(() => {
    if (withAnimation) {
      const onPageLoad = () => {
        setShowUrgencyTag(true);
      };

      if (document.readyState === 'complete') {
        onPageLoad();
      } else {
        window.addEventListener('load', onPageLoad, false);
        return () => window.removeEventListener('load', onPageLoad);
      }
    }
  }, [withAnimation]);

  return showUrgencyTag ? (
    <S.Container withAnimation={withAnimation}>
      <S.Content>
        {urgencyTagIcon ? <img src={urgencyTagIcon} alt="🔥" /> : null}
        <span dangerouslySetInnerHTML={{ __html: urgencyTagText }} />
      </S.Content>
      <S.Close onClick={() => setShowUrgencyTag(!showUrgencyTag)}>
        <S.CloseIcon alt="×" />
      </S.Close>
    </S.Container>
  ) : null;
};

export default UrgencyTag;
