import { Builder } from '@builder.io/react';
import Faqs from './Faqs';

Builder.registerComponent(Faqs, {
  name: 'Faqs',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2'],
      defaultValue: 'version1'
    },
    {
      name: 'questions',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'question',
          type: 'string',
          defaultValue: '',
          required: true
        },
        {
          name: 'answer',
          type: 'richText',
          defaultValue: '',
          required: true
        },
        {
          name: 'open',
          type: 'boolean',
          defaultValue: false,
          required: false
        }
      ]
    },
    {
      name: 'questionColor',
      type: 'color',
      required: false
    },
    {
      name: 'answerColor',
      type: 'color',
      required: false
    },
    {
      name: 'borderColor',
      type: 'color',
      required: false
    },
    {
      name: 'arrowColor',
      type: 'color',
      required: false
    },
    {
      name: 'plusInLeft',
      type: 'boolean',
      required: false
    },
    {
      name: 'fullWidth',
      type: 'boolean',
      required: false
    },
    {
      name: 'customStyles',
      type: 'string',
      enum: ['default', 'ingredients', 'pdp2023'],
      required: false
    }
  ]
});
