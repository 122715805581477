import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  ${({ maxWidth }) => `
    max-width: ${maxWidth || 1150}px;
    & .okeReviews[data-oke-container].oke-w, div.okeReviews.oke-w {
      max-width: ${maxWidth || 1150}px;
    }
  `}
`;
