import React, { useContext, useEffect, useMemo, useState } from 'react';
import * as S from './AddToCartButtonV1.styles';
import { addToCart } from '../../utils/addToCart';
import FunnelContext from '../../context/FunnelContext';
import { Builder } from '@builder.io/react';
import { addToSubscription } from '../../utils/activeSubscriptions';
import { navigate } from 'gatsby';
import useLocalStorage from '../../hooks/useLocalStorage';
import loadable from '@loadable/component';
import useButtonEventPressed from '../../hooks/useButtonEventPressed';

const CrossSellPopup = loadable(() => import('./CrossSellPopup'));

const AddToCartButton = props => {
  const {
    buttonText,
    children,
    triggerCheckoutButton,
    overrideColors,
    textColor,
    backgroundColor,
    backgroundColorHover,
    crossSellEndpointApiUrl,
    crossSellFirstDeliveryAmount,
    crossSellThenDeliveryAmount,
    crossSellShowPopup,
    bumpOfferNewPageUrl,
    hideExpressCheckout = false,
    insertDatalayer,
    styles = `default`,
    isUpsellPopup,
    isUpsellOffer,
    upsellOfferIndex
  } = props;

  const sendToStagingCheckout = process.env.GATSBY_FUNNEL_STAGING;
  const overrideStagingCheckoutUrl =
    process.env.GATSBY_CHECKOUT_API_URL_STAGING;
  const stagingCheckoutUrls = useMemo(
    () =>
      process.env.GATSBY_FUNNEL_STAGING_URLS?.split('|') || [
        process.env.GATSBY_CHECKOUT_API_URL_STAGING
      ],
    []
  );

  const context = useContext(FunnelContext);

  useButtonEventPressed('button-press-trigger');

  const {
    upsell: upsellUrl,
    currency,
    onetime,
    subscription,
    currentCategory,
    currentProduct,
    path,
    funnelUrl,
    pageDesign,
    bumpOfferChecked,
    bumpOfferIndex,
    bumpOfferArray,
    setBumpOfferIndex,
    frequency,
    billingFrequency,
    setSubmittedToCart,
    tubIndexChecked,
    extraObjects,
    answers: answersContext,
    setShowUpsellPopup,
    setOpenUpsellPopupAnimation
  } = context;

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [crossSellConfirmed, setCrossSellConfirmed] = useState(
    crossSellShowPopup ?? false
  );
  const [, setCrossSellResponse] = useLocalStorage('cross-sell-response-data');
  const [, setLocalCart] = useLocalStorage('local-cart');
  const [stagingCheckoutUrl, setStagingCheckoutUrl] = useState(
    overrideStagingCheckoutUrl
  );

  useEffect(() => {
    if (sendToStagingCheckout) {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      });
      if (params) {
        setStagingCheckoutUrl(
          params.checkoutUrl || stagingCheckoutUrls[params.cko || 0]
        );
      }
    }
  }, [
    sendToStagingCheckout,
    setStagingCheckoutUrl,
    overrideStagingCheckoutUrl,
    stagingCheckoutUrls
  ]);

  const triggerAddToCart = props => {
    const { saveLocalCart = false, bumpOfferNewPageUrl = '' } = props || {};

    if (isUpsellPopup) {
      return setShowUpsellPopup(true);
    } else if (Builder.isEditing) {
      return false;
    }
    if (buttonClicked) {
      return false;
    }

    if (insertDatalayer) {
      insertDatalayer.forEach(({ objects }) => {
        const datalayer = {};
        if (objects) {
          objects.forEach(({ key, value, valueInAnswers }) => {
            datalayer[key] = valueInAnswers ? answersContext[value] : value;
          });
        }
        window?.dataLayer?.push(datalayer);
      });
    }

    let newUpsellUrl = '';
    if (sendToStagingCheckout) {
      const urlFromQuery = new URLSearchParams(window.location.search).get(
        'upsellUrl'
      );
      if (urlFromQuery) {
        newUpsellUrl = urlFromQuery;
      }
    }
    let upsell_url = newUpsellUrl;
    let otpUpsellUrl = newUpsellUrl;
    let subUpsellUrl = newUpsellUrl;
    if (!upsell_url || upsell_url === '') {
      upsell_url = upsellUrl;
    }
    if (!otpUpsellUrl || otpUpsellUrl === '') {
      otpUpsellUrl = false;
    }
    if (!subUpsellUrl || subUpsellUrl === '') {
      subUpsellUrl = false;
    }

    const extraData = {
      ...extraObjects,
      currency_code: currency.code,
      currency_symbol: currency.symbol,
      trigger_checkout_button: triggerCheckoutButton,
      sendToStagingCheckout: sendToStagingCheckout ?? false,
      overrideStagingCheckoutUrl: stagingCheckoutUrl,
      setButtonDisabled,
      setButtonClicked,
      setSubmittedToCart,
      upsell_url,
      sub_upsell_url: subUpsellUrl || extraObjects.sub_upsell_url,
      otp_upsell_url: otpUpsellUrl || extraObjects.otp_upsell_url,
      saveLocalCart,
      bumpOfferNewPageUrl,
      setLocalCart,
      ...(hideExpressCheckout && { hide_express_checkout: true })
    };
    if (bumpOfferChecked && extraData?.bump_offer_index_override) {
      setBumpOfferIndex(extraData.bump_offer_index_override);
    }

    let multiple_bump_offer = [];
    const currentBumpOffers =
      currentCategory === `onetime`
        ? onetime.bumpoffers
        : subscription.bumpoffers;

    let upsellBumpOffers = isUpsellOffer ? [upsellOfferIndex] : [];
    let bumpOfferArrayFinal = [...bumpOfferArray, ...upsellBumpOffers];
    if (bumpOfferArrayFinal.length) {
      multiple_bump_offer = currentBumpOffers.filter((_, i) =>
        bumpOfferArrayFinal.includes(i)
      );
      Object.assign(extraData, { multiple_bump_offer });
    }

    setButtonDisabled(true);
    setButtonClicked(true);
    setSubmittedToCart(true);

    const productPrefix =
      currentCategory === `onetime` ? `onetime` : `subscription`;
    let currentProductId = currentProduct;
    if (tubIndexChecked && extraData?.tub_index_override) {
      currentProductId = `${productPrefix}_${extraData?.tub_index_override}`;
    }

    const { discounts, products } =
      currentCategory === 'subscriptions' ? subscription : onetime;

    const product = products
      .filter(({ tags }) => {
        if (extraObjects?.filterProductItemsByTag) {
          return tags.includes(extraObjects?.filterProductItemsByTag);
        }
        return true;
      })
      .find((_, index) => `${productPrefix}_${index}` === currentProductId);

    product.schedule = currentCategory === 'subscriptions' ? frequency : '';
    product.billingFrequency =
      currentCategory === 'subscriptions' ? billingFrequency : null;

    setOpenUpsellPopupAnimation(null);
    setShowUpsellPopup(false);

    const {
      overrideBumpOfferCategory = false,
      bumpOfferCategory = 'subscriptions'
    } = extraObjects;
    const bumpOfferCategoryFinal = overrideBumpOfferCategory
      ? bumpOfferCategory
      : currentCategory;
    const { bumpoffers } =
      bumpOfferCategoryFinal === 'subscriptions' ? subscription : onetime;
    let bumpOfferData = bumpOfferChecked ? bumpoffers[bumpOfferIndex] : {};
    bumpOfferData = Object.assign(bumpOfferData, {
      onetime: bumpOfferCategoryFinal === 'onetime'
    });

    void addToCart(
      product,
      discounts,
      extraData,
      bumpOfferData,
      path,
      funnelUrl,
      newUpsellUrl
    );
  };
  const triggerAddToActiveSubscription = () => {
    setButtonDisabled(true);
    setButtonClicked(true);
    setSubmittedToCart(true);

    const productPrefix =
      currentCategory === `onetime` ? `onetime` : `subscription`;
    const { products } =
      currentCategory === 'subscriptions' ? subscription : onetime;
    const { checkoutData } = products.find(
      (_, index) => `${productPrefix}_${index}` === currentProduct
    );

    addToSubscription({
      apiUrl: crossSellEndpointApiUrl,
      extra: extraObjects,
      checkoutData: checkoutData
    }).then(response => {
      setCrossSellResponse({
        ...response?.data,
        checkoutData: checkoutData
      });
      navigate(`${path}/confirmation`, {
        replace: true
      });
      setButtonDisabled(false);
      setButtonClicked(false);
      setSubmittedToCart(false);
    });
  };

  const triggerOnClick = () => {
    if (!isUpsellPopup && Builder.isEditing) {
      return false;
    }
    switch (triggerCheckoutButton) {
      case 'update-active-subscription':
        return triggerAddToActiveSubscription();
      case 'cross-sell':
        if (extraObjects?.active_subscription_id) {
          setCrossSellConfirmed(true);
        }
        break;
      case 'bump-offer-new-page':
        return triggerAddToCart({
          saveLocalCart: true,
          bumpOfferNewPageUrl
        });
      default:
      case 'none':
        return triggerAddToCart();
    }
  };

  return (
    <S.Container
      styles={styles}
      buttonClicked={buttonClicked}
      className="button-press-trigger"
    >
      <S.AddToCartButton
        type="button"
        onClick={triggerOnClick}
        disabled={buttonDisabled ? 'disabled' : ''}
        className={`${buttonClicked ? 'processing' : ''} add-to-card-button ${
          children ? `with-children` : ``
        }`}
        pageDesign={pageDesign}
        textColor={textColor}
        backgroundColor={backgroundColor}
        backgroundColorHover={backgroundColorHover}
        overrideColors={overrideColors}
      >
        {children ? children : <span>{buttonText}</span>}
      </S.AddToCartButton>
      {triggerCheckoutButton === `cross-sell` && (
        <CrossSellPopup
          show={crossSellConfirmed}
          setShow={setCrossSellConfirmed}
          endpointUrl={crossSellEndpointApiUrl}
          firstDelivery={crossSellFirstDeliveryAmount}
          thenDelivery={crossSellThenDeliveryAmount}
        />
      )}
    </S.Container>
  );
};

export default AddToCartButton;
