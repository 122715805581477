import { Builder, withChildren } from '@builder.io/react';
import CategorySelector from './CategorySelector';

Builder.registerComponent(withChildren(CategorySelector), {
  name: 'Category Selector',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2', 'children-only'],
      defaultValue: 'version1'
    },
    {
      name: 'otpLabel',
      type: 'string',
      defaultValue: 'One-Time Purchase',
      required: true,
      showIf: o => o.get('designVersion') === 'version1'
    },
    {
      name: 'subLabel',
      type: 'string',
      defaultValue: 'Subscribe and Save up to 40% + free gift!',
      required: true,
      showIf: o => o.get('designVersion') === 'version1'
    },
    {
      name: 'items',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'category',
          type: 'string',
          enum: ['subscriptions', 'onetime'],
          defaultValue: 'subscriptions'
        },
        {
          name: 'showTick',
          type: 'boolean',
          defaultValue: true
        }
      ],
      showIf: o =>
        ['version2', 'children-only'].includes(o.get('designVersion'))
    }
  ]
});
