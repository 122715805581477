import { Builder, withChildren } from '@builder.io/react';
import ToggleContainer from './ToggleContainer';

Builder.registerComponent(withChildren(ToggleContainer), {
  name: 'Slides:ToggleContainer',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['single', 'multiple'],
      defaultValue: 'single'
    },
    {
      name: 'bgColor',
      type: 'color',
      defaultValue: '#BC2E3E'
    },
    {
      name: 'activeColor',
      type: 'color',
      defaultValue: '#e26447'
    },
    {
      name: 'hoverColor',
      type: 'color',
      defaultValue: '#e26447'
    },
    {
      name: 'extraObjects',
      type: 'list',
      subFields: [
        {
          name: 'key',
          type: 'string',
          required: true
        },
        {
          name: 'value',
          type: 'string',
          required: false
        },
        {
          name: 'check',
          type: 'boolean'
        },
        {
          name: 'type',
          type: 'string',
          defaultValue: 'string',
          enum: ['string', 'list', 'boolean']
        }
      ],
      showIf: o => o.get('designVersion') === 'single'
    },
    {
      name: 'containers',
      type: 'list',
      subFields: [
        {
          name: 'extraObjects',
          type: 'list',
          subFields: [
            {
              name: 'key',
              type: 'string',
              required: true
            },
            {
              name: 'value',
              type: 'string',
              required: false
            },
            {
              name: 'check',
              type: 'boolean'
            },
            {
              name: 'type',
              type: 'string',
              defaultValue: 'string',
              enum: ['string', 'list', 'boolean']
            }
          ]
        },
        {
          name: 'setAnswer',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'currentTubIndex',
          type: 'number',
          defaultValue: 0
        },
        {
          name: 'currentCategory',
          type: 'string',
          defaultValue: 'subscriptions',
          enum: ['subscriptions', 'onetime']
        },
        {
          name: 'selected',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'setFilterByTag',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'filterByTagName',
          type: 'string',
          defaultValue: ''
        }
      ],
      showIf: o => o.get('designVersion') === 'multiple'
    },
    {
      name: 'direction',
      type: 'string',
      enum: ['row', 'column'],
      defaultValue: 'column',
      showIf: o => o.get('designVersion') === 'multiple'
    },
    {
      name: 'setAnswer',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'showTickbox',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'useCheckmark',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'tickboxColor',
      type: 'color',
      defaultValue: '#e26447',
      showIf: o => o.get('showTickbox')
    },
    {
      name: 'tickboxColorActive',
      type: 'color',
      defaultValue: '#e26447',
      showIf: o => o.get('showTickbox')
    },
    {
      name: 'tickboxPositionTop',
      type: 'number',
      defaultValue: '10',
      showIf: o => o.get('showTickbox')
    },
    {
      name: 'tickboxPositionLeft',
      type: 'number',
      defaultValue: '10',
      showIf: o => o.get('showTickbox')
    },
    {
      name: 'tickboxRadius',
      type: 'string',
      defaultValue: '100%'
    },
    {
      name: 'currentTubIndex',
      type: 'number',
      defaultValue: 0,
      showIf: o => o.get('designVersion') === 'single'
    },
    {
      name: 'currentCategory',
      type: 'string',
      defaultValue: 'subscriptions',
      enum: ['subscriptions', 'onetime'],
      showIf: o => o.get('designVersion') === 'single'
    },
    {
      name: 'checkAnswer',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'checkAnswerKey',
      type: 'string',
      defaultValue: '',
      showIf: o => o.get('checkAnswer')
    },
    {
      name: 'checkAnswerValue',
      type: 'string',
      defaultValue: '',
      showIf: o => o.get('checkAnswer')
    }
  ]
});
