import { Builder, withChildren } from '@builder.io/react';
import ShowHide from './ShowHide';

Builder.registerComponent(withChildren(ShowHide), {
  name: 'Show Hide',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'buttonOpenText',
      type: 'string',
      defaultValue: false
    },
    {
      name: 'buttonCloseText',
      type: 'string',
      defaultValue: false
    }
  ],
  withChildren: true
});
