import { Builder, withChildren } from '@builder.io/react';
import ToggleButton from './ToggleButton';

Builder.registerComponent(withChildren(ToggleButton), {
  name: 'Toggle Button',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1'],
      defaultValue: 'version1'
    },
    {
      name: 'extraObjects',
      type: 'list',
      subFields: [
        {
          name: 'key',
          type: 'string',
          required: true
        },
        {
          name: 'value',
          type: 'string',
          required: false
        },
        {
          name: 'check',
          type: 'boolean'
        },
        {
          name: 'type',
          type: 'string',
          defaultValue: 'string',
          enum: ['string', 'list', 'boolean']
        }
      ]
    }
  ]
});
