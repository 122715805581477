import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const VariantContainer = loadable(() => import('./VariantContainer'));

Builder.registerComponent(withChildren(VariantContainer), {
  name: 'Variant Container',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'variantId',
      type: 'string',
      enum: [
        'DEFAULT',
        'SUB10',
        'SUB15',
        'SUB20',
        'SUB20v2',
        'SUB25',
        'SUB30',
        'SUB30v2',
        'SUB30LockIn',
        'SUB35',
        'SUB35v2',
        'SUB35LockIn',
        'SUB40',
        'SUB45',
        'SUB50',
        'OTP10',
        'OTP15',
        'OTP20',
        'OTP25',
        'OTP30',
        'OTP35',
        'OTP40',
        'OTP45',
        'OTP50'
      ],
      defaultValue: 'DEFAULT',
      required: true
    }
  ]
});
