import CheckoutSdk from '@petlab/checkout-sdk';
import { getProductTitle } from './getProductTitle';
import utmTracking from './utmTracking';
import { getCheckoutOverrides } from './getCheckoutOverrides';

const Checkout = new CheckoutSdk(
  process.env.GATSBY_CHECKOUT_API_URL,
  process.env.GATSBY_CHECKOUT_STORE_NAME,
  process.env.GATSBY_CHECKOUT_API_KEY
);

export const addToCart = async (
  product,
  discountCodes = [],
  extraData = {},
  bumpOffer = {},
  path = '',
  funnelUrl = '',
  newUpsellUrl = ''
) => {
  try {
    // build checkout params
    const items = [];
    let finalDiscountCodes = discountCodes;

    const sizesArr = ['small', 'medium', 'large'];
    const weightTag = sizesArr.find(
      item => item === extraData?.filterProductItemsByTag
    );
    const weight = extraData?.weight || weightTag;

    let productItem = {
      variant_id: product.checkoutData.variant_id,
      quantity: product.data.quantity ?? 1,
      schedule: product?.schedule ? product?.schedule : '',
      title: getProductTitle(
        product?.productRef?.checkoutTitle,
        product?.data?.checkoutTitle
      ),
      sub_upgrade_variant_id: parseInt(extraData?.sub_upgrade_variant_id || 0),
      sub_upgrade_free_gift_id: parseInt(
        extraData?.sub_upgrade_free_gift_id || 0
      ),
      ...(weight ? { pet_size: weight[0].toUpperCase() } : {})
    };

    if (product?.billingFrequency) {
      Object.assign(productItem, {
        billing_frequency: product.billingFrequency
      });
    }

    const sub_upgrade_enabled = !!(
      extraData?.sub_upgrade_enabled && extraData?.sub_upgrade_enabled !== '0'
    );
    if (sub_upgrade_enabled) {
      productItem = Object.assign(productItem, {
        sub_upgrade_enabled,
        sub_upgrade_variant_id: parseInt(
          extraData?.sub_upgrade_variant_id || 0
        ),
        sub_upgrade_free_gift_id: parseInt(
          extraData?.sub_upgrade_free_gift_id || 0
        )
      });
    }

    if (extraData?.sub_lock_in_enabled) {
      productItem = Object.assign(productItem, {
        sub_lock_in_enabled: extraData?.sub_lock_in_enabled,
        sub_lock_in_schedule: extraData?.sub_lock_in_schedule
      });
    }

    if (product?.subProduct) {
      productItem.appropriate_sub_variant_id =
        product?.subProduct?.data?.variantId;
      const [discount_code] = product?.subProduct?.discounts;
      if (discount_code) {
        finalDiscountCodes.push(discount_code);
      }
    }

    if (product?.discount_code_override?.length > 0) {
      finalDiscountCodes = product?.discount_code_override ?? [];
    }

    items.push(productItem);

    // add bump offer
    if (bumpOffer?.checkoutData) {
      items.push({
        variant_id: bumpOffer.checkoutData.variant_id,
        quantity: bumpOffer.data.quantity ?? 1,
        title: getProductTitle(
          bumpOffer?.productRef?.checkoutTitle,
          bumpOffer?.data?.checkoutTitle
        ),
        bump: true,
        perceived_rrp: bumpOffer.checkoutData.perceived_rrp,
        discounted_price: bumpOffer.checkoutData.discounted_price,
        image: bumpOffer.checkoutData.image,
        display_title: bumpOffer?.display_title,
        schedule: bumpOffer?.onetime
          ? ''
          : product?.schedule
          ? product?.schedule
          : '',
        'bump-offer': true
      });
      const bumpOfferDiscountCode =
        bumpOffer?.productRef?.product?.discountCode;
      finalDiscountCodes.push(bumpOfferDiscountCode);
    }

    // add free gift
    let freeGift = {};
    let freeGiftItems = {};
    if (product.freeGiftData?.variantId) {
      freeGift = Object.assign(freeGift, {
        discount_codes: product.free_gift_discount_code?.split(',') ?? [],
        gift_for_otp: extraData?.currentCategory === 'onetime'
      });
      freeGiftItems = Object.assign(freeGiftItems, {
        default_free_gift: {
          variant_id: product.freeGiftData?.variantId,
          quantity: product.freeGiftData?.quantity,
          free_gift_value: product.free_gift_value,
          checkoutTitle: getProductTitle(
            product.free_gift_checkout_title,
            product.freeGiftData?.checkoutTitle
          )
        }
      });
    }
    if (extraData?.bandana_variant_id) {
      freeGiftItems = Object.assign(freeGiftItems, {
        bandana: {
          variant_id: extraData?.bandana_variant_id,
          quantity: 1,
          free_gift_value: extraData?.bandana_value ?? ''
        }
      });
    }

    // add additional gifts
    if (product?.additionalFreeGifts) {
      product?.additionalFreeGifts?.map((aFreeGift, index) => {
        return Object.assign(freeGiftItems, {
          [`freegift_${index}`]: {
            variant_id: aFreeGift?.variantId,
            quantity: aFreeGift?.freeGiftQuantity ?? aFreeGift?.quantity,
            free_gift_value: aFreeGift?.freeGiftValue ?? '',
            checkoutTitle: getProductTitle(
              aFreeGift?.freeGiftCheckoutTitle,
              aFreeGift?.checkoutTitle
            )
          }
        });
      });
    }

    freeGift = Object.assign(freeGift, {
      items: freeGiftItems
    });

    // multiple bump offers
    if (extraData?.multiple_bump_offer) {
      extraData.multiple_bump_offer = extraData?.multiple_bump_offer.map(
        bumpOfferM => {
          if (bumpOfferM) {
            items.push({
              variant_id: bumpOfferM.checkoutData.variant_id,
              quantity: bumpOfferM.data.quantity ?? 1,
              title: getProductTitle(
                bumpOfferM?.productRef?.checkoutTitle,
                bumpOfferM?.data?.checkoutTitle
              ),
              bump: true,
              perceived_rrp: bumpOfferM.checkoutData.perceived_rrp,
              discounted_price: bumpOfferM.checkoutData.discounted_price,
              image: bumpOfferM.checkoutData.image,
              display_title: bumpOfferM?.display_title,
              schedule: bumpOfferM?.onetime
                ? ''
                : product?.schedule
                ? product?.schedule
                : '',
              'bump-offer': true
            });
            discountCodes.push(bumpOfferM?.productRef?.product?.discountCode);
          }
          return bumpOfferM;
        }
      );
    }

    const tracking = utmTracking(path);

    let {
      upsell_url,
      shipping,
      pixel_ids,
      klaviyo_list,
      is_new_subscription,
      currency_code,
      currency_symbol,
      expedited_delivery_price,
      expedited_delivery,
      countries_allowed,
      language,
      setButtonClicked,
      trigger_checkout_button,
      address_validator_enabled,
      dm_gift_flow_free_gifts,
      rebrand_styling,
      klarna_enabled,
      express_require_email,
      express_checkout_v2,
      chosen_language,
      one_page_checkout_test_enabled,
      shipping_cost_enabled,
      petInfo,
      hide_express_checkout,
      different_otpvssub_upsells,
      otp_upsell_url,
      sub_upsell_url,
      bump_offer_ability = 0,
      express_shipping_enabled,
      receipt_popup_upsell,
      split_test_version
    } = extraData;

    //expect comma separated strings from builder then convert to array of ids
    const pixelIds = pixel_ids.split(',').map(id => parseInt(id));
    const isNewSubscription = !!is_new_subscription;
    const currencyCode = currency_code ? currency_code : 'USD';
    const currencySymbol = currency_symbol ? currency_symbol : '$';
    const expeditedDeliveryPrice = expedited_delivery_price
      ? parseFloat(expedited_delivery_price)
      : 0;
    const expeditedDelivery = !!expedited_delivery;
    const countriesAllowed = countries_allowed?.split(',') ?? [];
    const lang = language ?? '';
    const checkout = extraData.sendToStagingCheckout
      ? extraData.overrideStagingCheckoutUrl ||
        process.env.GATSBY_CHECKOUT_API_URL_STAGING
      : process.env.GATSBY_CHECKOUT_API_URL;
    const one_click_paypal = trigger_checkout_button === 'paypal';
    const addressValidatorEnabled =
      address_validator_enabled && address_validator_enabled !== '0';
    const dmGiftFlowFreeGifts =
      dm_gift_flow_free_gifts && dm_gift_flow_free_gifts !== '0';
    const rebrandStyling = rebrand_styling && rebrand_styling !== '0';
    const klarnaEnabled = klarna_enabled && klarna_enabled !== '0';
    const expressRequireEmail =
      express_require_email && express_require_email !== '0';
    const expressCheckoutV2 =
      express_checkout_v2 && express_checkout_v2 !== '0';
    const chosenLanguage =
      chosen_language && chosen_language !== '' ? chosen_language : 'EN';
    const finalUpsellUrl = newUpsellUrl
      ? newUpsellUrl
      : product.upsell_url ?? upsell_url;
    const opcEnabled =
      one_page_checkout_test_enabled && one_page_checkout_test_enabled !== '0';
    const shippingCostEnabled =
      shipping_cost_enabled && shipping_cost_enabled !== '0';
    const hideExpressCheckout =
      hide_express_checkout && hide_express_checkout !== '0';
    const differentOtpvssubUpsells =
      different_otpvssub_upsells && different_otpvssub_upsells !== '0';
    const expressShippingEnabled =
      express_shipping_enabled && express_shipping_enabled !== '0';
    const receiptPopupUpsell =
      receipt_popup_upsell && receipt_popup_upsell !== '0';

    let sub_discount_percentage = (
      ((product.checkoutData.perceived_rrp -
        product.checkoutData.discounted_price) /
        product.checkoutData.perceived_rrp) *
      100
    ).toFixed(0);

    if (extraData.overrideSubDiscountPercentage) {
      sub_discount_percentage = extraData.overrideSubDiscountPercentage;
    }

    let extraPayload = {
      one_click_paypal,
      address_validator_enabled: addressValidatorEnabled, // address_validator_enabled: boolean
      dm_gift_flow_free_gifts: dmGiftFlowFreeGifts, // dm_gift_flow_free_gifts: boolean
      rebrand_styling: rebrandStyling, // rebrand_styling: boolean
      klarna_enabled: klarnaEnabled, // klarna_enabled: boolean
      express_require_email: expressRequireEmail, // express_require_email: boolean
      express_checkout_v2: expressCheckoutV2, // express_checkout_v2: boolean
      chosen_language: chosenLanguage, // chosen_language: string
      one_page_checkout_test_enabled: opcEnabled, // boolean
      shipping_cost_enabled: shippingCostEnabled, // boolean
      hide_express_checkout: hideExpressCheckout, // boolean
      different_otpvssub_upsells: differentOtpvssubUpsells, // boolean
      otp_upsell_url,
      sub_upsell_url,
      bump_offer_ability,
      express_shipping_enabled: expressShippingEnabled, // boolean
      receipt_popup_upsell: receiptPopupUpsell,
      split_test_version: parseInt(split_test_version),
      sub_discount_percentage
    };

    // petInfo optional
    if (petInfo && Object.keys(petInfo).length > 0) {
      Object.assign(extraPayload, {
        pets: [petInfo]
      });
    }

    // free subscription gift
    let freeSubscriptionGift = {
      flow_tag: product?.freeSubscriptionGift?.flowTag,
      free_gift: {
        checkoutTitle:
          product?.freeSubscriptionGift?.freeGift?.checkoutTitle ?? '',
        quantity: product?.freeSubscriptionGift?.freeGift?.quantity ?? 1,
        variantId: product?.freeSubscriptionGift?.freeGift?.variantId ?? ''
      }
    };

    // add rebill_discount & higher_initial_discount vars if it's present
    const { rebill_discount, higher_initial_discount } = product;

    if (
      (rebill_discount && rebill_discount.length > 0) ||
      (higher_initial_discount && higher_initial_discount.length > 0)
    ) {
      const higherInitialDiscount = higher_initial_discount ?? [];
      const rebillDiscount = rebill_discount ?? [];

      if (bumpOffer?.checkoutData) {
        higherInitialDiscount.push(
          bumpOffer?.higher_initial_discount ||
            bumpOffer?.productRef?.product?.discountCode
        );
        rebillDiscount.push(
          bumpOffer?.rebill_discount ||
            bumpOffer?.productRef?.product?.discountCode
        );
      }

      extraPayload = Object.assign(extraPayload, {
        higher_initial_discount: higherInitialDiscount,
        rebill_discount: rebillDiscount
      });
    }

    if (product?.priceSettingTag) {
      extraPayload = Object.assign(extraPayload, {
        price_setting_tag: parseInt(product?.priceSettingTag)
      });
    }

    const CheckoutStaging = new CheckoutSdk(
      extraData.overrideStagingCheckoutUrl ||
        process.env.GATSBY_CHECKOUT_API_URL_STAGING,
      process.env.GATSBY_CHECKOUT_STORE_NAME,
      process.env.GATSBY_CHECKOUT_API_KEY_STAGING
    );

    const CheckoutAPI = extraData.sendToStagingCheckout
      ? CheckoutStaging
      : Checkout;

    const checkoutOverrides = getCheckoutOverrides();
    if (checkoutOverrides) {
      shipping = checkoutOverrides?.shipping
        ? checkoutOverrides?.shipping
        : shipping;
      if (checkoutOverrides?.extra) {
        Object.assign(extraPayload, checkoutOverrides?.extra);
      }
    }

    if (extraData.saveLocalCart) {
      if (typeof setButtonClicked === `function`) {
        extraData.setButtonDisabled(false);
        extraData.setButtonClicked(false);
        extraData.setSubmittedToCart(false);
      }

      let totalAmount = 0;
      totalAmount =
        totalAmount + (product?.checkoutData?.discounted_price || 0);

      let totalSavings = 0;
      totalSavings =
        totalSavings +
        parseFloat(
          product?.checkoutData?.perceived_rrp -
            product?.checkoutData?.discounted_price || 0
        );
      totalSavings =
        parseFloat(totalSavings) + parseFloat(product?.free_gift_value || 0);

      if (bumpOffer?.checkoutData) {
        totalAmount =
          totalAmount + (bumpOffer?.checkoutData?.discounted_price || 0);
        totalSavings =
          totalSavings +
          parseFloat(
            bumpOffer?.checkoutData?.perceived_rrp -
              bumpOffer?.checkoutData?.discounted_price || 0
          );
      }

      extraData.setLocalCart({
        product,
        discountCodes,
        extraData,
        bumpOffer,
        path,
        checkout,
        items,
        finalDiscountCodes,
        finalUpsellUrl,
        tracking,
        shipping: parseFloat(shipping), // shipping: number,
        pixelIds, // pixel_ids: string[],
        klaviyo_list, // klaviyo_list: string,
        isNewSubscription, // is_new_subscription: boolean,
        freeSubscriptionGift, // freeSubscriptionGift: { flow_tag: [], free_gift: [] },
        expeditedDeliveryPrice, // expedited_delivery_price: number,
        expeditedDelivery, // expedited_delivery: boolean,
        currency: { code: currencyCode, symbol: currencySymbol }, // currency: { code: string, symbol: string },
        countriesAllowed, // countries_allowed: string[],
        lang, // language: string,
        freeGift, // freeGift: { discount_codes: [], items: { free_1: {} } }
        funnelUrl, // returnUrl: string
        extraPayload, // {}
        totalAmount,
        totalSavings
      });

      window.location = extraData.bumpOfferNewPageUrl;
    } else {
      const { redirect } = await CheckoutAPI.createCheckout(
        checkout,
        items,
        finalDiscountCodes,
        finalUpsellUrl,
        tracking,
        parseFloat(shipping), // shipping: number,
        pixelIds, // pixel_ids: string[],
        klaviyo_list, // klaviyo_list: string,
        isNewSubscription, // is_new_subscription: boolean,
        freeSubscriptionGift, // freeSubscriptionGift: { flow_tag: [], free_gift: [] },
        expeditedDeliveryPrice, // expedited_delivery_price: number,
        expeditedDelivery, // expedited_delivery: boolean,
        { code: currencyCode, symbol: currencySymbol }, // currency: { code: string, symbol: string },
        countriesAllowed, // countries_allowed: string[],
        lang, // language: string,
        freeGift, // freeGift: { discount_codes: [], items: { free_1: {} } }
        funnelUrl, // returnUrl: string
        extraPayload // {}
      );

      if (typeof setButtonClicked === `function`) {
        extraData.setButtonDisabled(false);
        extraData.setButtonClicked(false);
        extraData.setSubmittedToCart(false);
      }

      let redirectUrl = redirect;
      if (extraData?.checkout_url_append) {
        redirectUrl = redirectUrl + extraData?.checkout_url_append;
      }

      if (extraData?.checkout_url_replace_shipping) {
        redirectUrl = redirectUrl.replace(
          '/shipping',
          `/${extraData?.checkout_url_replace_shipping}`
        );
      }

      // redirect to cart page
      window.location = redirectUrl;
    }
  } catch (e) {
    console.log('error creating checkout!!');
    console.log(e);
  }
};
