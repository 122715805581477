import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                box-shadow: 0px 0px 6px #0000001A;
                border: 1px solid #E5E8F1;
            `;
      default:
        return ``;
    }
  }}

  &.rift {
    border-radius: 4px;
    border: 1px solid #ccd2e3;
    & .tabs > .sub {
      border-top-left-radius: 4px;
      border-bottom: 1px solid #ccd2e3;
      box-shadow: inset -3px 0px 3px #ccd2e3;
      &.active {
        border-right: 1px solid #ccd2e3;
        border-bottom: none;
        box-shadow: none;
      }
    }
    & .tabs > .otp {
      border-top-right-radius: 4px;
      border-top-left-radius: 0px;
      border-bottom: 1px solid #ccd2e3;
      box-shadow: inset 3px 0px 3px #ccd2e3;
      &.active {
        border-bottom: none;
        border-left: 1px solid #ccd2e3;
        box-shadow: none;
      }
    }
  }

  &.big-font-title {
    border-radius: 4px;
    border: 1px solid #ccd2e3;
    & .tabs {
      background-color: #ccd2e3;
    }
    & .tabs > .sub {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom: 1px solid #ccd2e3;
      box-shadow: inset -3px 0 3px #ccd2e3;
      &.active {
        border-right: 1px solid #ccd2e3;
        border-bottom: none;
        box-shadow: none;
      }
    }
    & .tabs > .otp {
      border-top-right-radius: 4px;
      border-top-left-radius: 0;
      border-bottom: 1px solid #ccd2e3;
      box-shadow: inset 3px 0 3px #ccd2e3;
      margin-left: auto;
      &.active {
        border-bottom: none;
        border-top-left-radius: 4px;
        border-left: 1px solid #ccd2e3;
        box-shadow: none;
      }
    }
  }
`;

export const Tabs = styled.div`
  display: flex;
`;

export const TabContent = styled.div`
  padding: 15px;
  &.free-trial {
    padding-top: 0;
  }
  @media (max-width: 500px) {
    padding: 13px;
  }
`;
export const QuantityWithButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f8f8f8;
  padding: 14px 12px 14px 8px;
`;

export const SubsPlan = styled.div`
  display: flex;
  margin-top: 5px;
  @media (max-width: 500px) {
    display: block;
    padding: 10px;
  }
`;

export const SubsPlanItem = styled.div`
  flex: 0 0 50%;
  & p {
    margin: 0 0 0 10px;
    font-size: 12px;
  }

  @media (max-width: 500px) {
    width: 100%;
    & p {
      margin: 0;
      font-size: 14px;
    }
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                & p {
                    margin: 0 0 0 21px;
                    color:#282A33;
                }
                `;
      default:
        return ``;
    }
  }}
`;

export const SubsPlanItemTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #e26447;
  margin-top: 14px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  & > i {
    color: #fff;
    font-size: 10px;
    background-color: #e26447;
    border-radius: 100%;
    padding: 3px 4px;
    margin-right: 5px;
    margin-top: -3px;
    display: inline-block;
    width: 17px;
    height: 17px;

    &.icon.icon-pencil::after {
      content: ' ';
      display: block;
      width: 8px;
      height: 10px;
      background-image: url('/images/fa-pencil.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &.icon.icon-close::after {
      content: ' ';
      display: block;
      width: 8px;
      height: 10px;
      background-image: url('/images/fa-close.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  @media (max-width: 500px) {
    font-size: 16px;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                font-weight: 400;
                color: #BC2E3E;
                @media (max-width: 500px) {
                        font-size: 14px;
                }
                & > i {
                  background-color: #BC2E3E;
                }
                `;
      default:
        return ``;
    }
  }}
`;

export const SubscriptionContent = styled.div``;

export const SubscriptionDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DiscountPercentage = styled.span`
  color: #bc2e3e;
`;

export const SubExtraInfoContent = styled.div`
  text-align: right;

  .display-title {
    font-size: 20px;
  }
`;

export const OnetimeContent = styled.div`
  padding-left: 40px;
  position: relative;
  &:before {
    content: '';
    background-repeat: no-repeat;
    height: 100px;
    width: 50px;
    position: absolute;
    top: -10px;
    left: 0;
  }
  & p {
    margin: 5px 0 0 0;
    color: #24252b;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                &:before {
                    background-image: url("/images/indicator-arrow2.svg");
                }
                & p {
                    font-size: 16px;
                    line-height: normal;
                }
                & p > .sub-price {
                    font-weight: 600;
                }
                    `;
      default:
        return `
                &:before {
                    background-image: url("/images/indicator-arrow.svg");
                }
                `;
    }
  }}
`;

export const SubPopup = styled.span`
  text-decoration: underline;
  cursor: pointer;
  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                    color: #BC2E3E;
                `;
      default:
        return `
                    color: #e26447;
                `;
    }
  }}
`;
