import React from 'react';
import loadable from '@loadable/component';

const TubSelectorV1 = loadable(() => import('./TubSelectorV1'));
const TubSelectorV1WithChildren = loadable(() =>
  import('./TubSelectorV1WithChildren')
);
const TubSelectorV2 = loadable(() => import('./TubSelectorV2'));
const TubSelectorV3 = loadable(() => import('./TubSelectorV3'));
const TubSelectorV4 = loadable(() => import('./TubSelectorV4'));
const TubSelectorDropDown = loadable(() => import('./TubSelectorDropDown'));
const ChildrenOnly = loadable(() => import('./ChildrenOnly'));
const ChildrenOnlyV2 = loadable(() => import('./ChildrenOnlyV2'));
const VolumeDiscount = loadable(() => import('./VolumeDiscount'));
const PlusMinusButtons = loadable(() => import('./PlusMinusButtons'));

const TubSelector = props => {
  const { designVersion } = props;

  switch (designVersion) {
    case 'plus-minus-buttons':
      return <PlusMinusButtons {...props} />;
    case 'children-only-v2':
      return <ChildrenOnlyV2 {...props} />;
    case 'volume-discount':
      return <VolumeDiscount {...props} />;
    case 'children-only':
      return <ChildrenOnly {...props} />;
    case 'version4':
      return <TubSelectorV4 {...props} />;
    case 'version3':
      return <TubSelectorV3 {...props} />;
    case 'version2':
      return <TubSelectorV2 {...props} />;
    case 'dropdown':
      return <TubSelectorDropDown {...props} />;
    case 'version1-with-children':
      return <TubSelectorV1WithChildren {...props} />;
    default:
    case 'version1':
      return <TubSelectorV1 {...props} />;
  }
};

export default TubSelector;
