import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const ActionContainer = loadable(() => import('./ActionContainer'));

Builder.registerComponent(withChildren(ActionContainer), {
  name: 'Action Container',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'action',
      type: 'string',
      enum: ['remove-by-element-id'],
      defaultValue: 'remove-by-element-id'
    },
    {
      name: 'elementIds',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'id',
          type: 'string',
          defaultValue: ''
        }
      ],
      showIf: o => o.get('action') === 'remove-by-element-id'
    }
  ],
  noWrap: true
});
