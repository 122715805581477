import React from 'react';
import loadable from '@loadable/component';

const AddToLocalCartButtonV1 = loadable(() =>
  import('./AddToLocalCartButtonV1')
);

const AddToLocalCartButton = props => {
  const { designVersion } = props;

  switch (designVersion) {
    default:
    case 'version1':
      return <AddToLocalCartButtonV1 {...props} />;
  }
};

export default AddToLocalCartButton;
