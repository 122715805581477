import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const RepurchaseForm = loadable(() => import('./RepurchaseForm'));

Builder.registerComponent(RepurchaseForm, {
  name: 'Repurchase Form',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1'],
      defaultValue: 'version1'
    },
    {
      name: 'title',
      type: 'string',
      defaultValue: ''
    }
  ]
});
