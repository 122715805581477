import React, { useContext } from 'react';
import * as S from './QuantitySelectTabs.styles';
import FunnelContext from '../../../context/FunnelContext';
import FunnelText from '../../FunnelText/FunnelText';

const SelectContainer = ({ items, useSelectComponent }) => {
  const {
    currentCategory,
    setTubIndex,
    currentTubIndex,
    setCurrentTubIndex,
    setCurrentProduct,
    extraObjects
  } = useContext(FunnelContext);

  const productPrefix =
    currentCategory === `onetime` ? `onetime` : `subscription`;

  const quantityOnChange = value => {
    setCurrentTubIndex(value);
    setTubIndex(value);
    setCurrentProduct(`${productPrefix}_${value}`);
  };

  const options = items.map(item => {
    const label =
      extraObjects?.recommendedTubIndex === item.tubIndex &&
      extraObjects?.setRecommendedTub &&
      extraObjects?.recommendedTubText &&
      extraObjects?.recommendedTubText;
    const mainLabel = item.label;
    const additionalText = item.additionalText;
    return { value: item.tubIndex, label, mainLabel, additionalText };
  });

  if (useSelectComponent) {
    return (
      <S.ButtonsContainer>
        {options.map(({ label, value, mainLabel, additionalText }) => (
          <S.Button
            key={value}
            onClick={() => quantityOnChange(value)}
            className={`${currentTubIndex === value ? `active` : ``}`}
          >
            {label ? <S.RecommendedBadge>{label}</S.RecommendedBadge> : ''}
            <S.ButtonTextWrapper>
              <S.ButtonTitle> {mainLabel} </S.ButtonTitle>
              <span>{additionalText}</span>
            </S.ButtonTextWrapper>
          </S.Button>
        ))}
      </S.ButtonsContainer>
    );
  } else {
    return '';
  }
};

const QuantitySelectTabs = ({
  items,
  useSelectComponent,
  label,
  hideItems,
  quantityLabelSmartText
}) => {
  const { currentCategory } = useContext(FunnelContext);

  return (
    <S.Container>
      {currentCategory === 'subscriptions' && (
        <S.FunnelTextContainer>
          <FunnelText text={quantityLabelSmartText} />
        </S.FunnelTextContainer>
      )}
      <S.Label htmlFor="quantity-select-input">{label}</S.Label>
      {!hideItems && (
        <SelectContainer
          useSelectComponent={useSelectComponent}
          items={items}
        />
      )}
    </S.Container>
  );
};

export default QuantitySelectTabs;
