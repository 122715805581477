import styled from 'styled-components';
import { queries } from '../../utils/mediaQueries';

export const Container = styled.div``;
export const TopTitle = styled.div`
  font-size: 20px;
  color: #73aa73;
  letter-spacing: 2px;
  font-weight: 600;
  margin: 10px 0 5px;

  ${({ pageDesign }) => {
    if (pageDesign === `design2`) {
      return `
        color: #BC2E3E;
        letter-spacing: 0px;
        font-weight:bold;
        `;
    }
  }}
`;
export const Name = styled.h1`
  font-size: 30px;
  line-height: 1;
  font-weight: 700;
  margin: 0;
  letter-spacing: 0.7px;
  width: calc(100% - 50px);

  @media ${queries.md} {
    font-size: 26px;
  }
  ${({ pageDesign }) => {
    if (pageDesign === `design2`) {
      return `
        font-family: "Utopia Std", serif;
        font-weight: 400;
        @media ${queries.md} {
          font-size: 26px;
        }
        `;
    }
  }}
`;
export const SubsTitle = styled.span`
  font-weight: 600;
  font-size: 20px;

  ${({ pageDesign }) => {
    if (pageDesign === `design2`) {
      return `
      font-weight: normal;
      font-size: 18px;
      `;
    }
  }} @media ${queries.sm} {
    font-size: 16px;
    display: block;
    margin-top: 10px;
  }
`;
