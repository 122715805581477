import React, { useState, useEffect, useContext, useRef } from 'react';
import * as S from './ToggleV2.styles';
import Modal from '../Modal/Modal';
import FunnelContext from '../../context/FunnelContext';
import { Builder } from '@builder.io/react';
import useScreenResize from '../../hooks/useScreenResize';

function ToggleV2(props) {
  const {
    children,
    isModal = false,
    modalWrapperBackgroundColor = 'rgba(204,204,204,0.5)',
    closeOnWrapperClick = false,
    triggerBuilderElement = false,
    builderElementId = 'modal-close-trigger',
    addMobileSlideAnimation = false,
    addDesktopSlideAnimation = true,
    containerHeight = 400,
    containerElementId = 'modal-container',
    triggerElementActions = [],
    duration = 200
  } = props;

  const currentElement = useRef(null);
  const [open, setOpen] = useState(false);
  const [mobileWithAnimation, setMobileWithAnimation] = useState(
    addMobileSlideAnimation
  );
  const [desktopWithAnimation, setDesktopWithAnimation] = useState(
    addDesktopSlideAnimation
  );
  const { setAccordionActiveIndex } = useContext(FunnelContext);

  useEffect(() => {
    if (addMobileSlideAnimation) {
      if (window.outerWidth > 600) {
        setMobileWithAnimation(false);
      }
    }
    if (addDesktopSlideAnimation) {
      if (window.outerWidth < 600) {
        setDesktopWithAnimation(false);
      }
    }
  }, [
    addMobileSlideAnimation,
    addDesktopSlideAnimation,
    setMobileWithAnimation,
    setDesktopWithAnimation
  ]);

  useScreenResize(() => {
    if (addMobileSlideAnimation) {
      if (window.outerWidth > 600) {
        setMobileWithAnimation(false);
      }
    }
    if (addDesktopSlideAnimation) {
      if (window.outerWidth < 600) {
        setDesktopWithAnimation(false);
      }
    }
  });

  const runOnContainerDetection = (elementId, callback) => {
    let timeout;
    const interval = setInterval(() => {
      const collection = document.getElementsByClassName(elementId);
      if (collection.length > 0) {
        callback(collection);
        clearInterval(interval);
        clearTimeout(timeout);
      }
    }, 1000);
    timeout = setTimeout(() => {
      clearInterval(interval);
    }, 5000);
  };

  const applyTrigger = () => {
    if (!triggerBuilderElement) return;
    runOnContainerDetection(builderElementId, collection => {
      Array.from(collection).forEach(function(element) {
        element.addEventListener('click', handleClose);
      });
    });
    if (triggerElementActions.length > 0) {
      triggerElementActions.forEach(
        ({ triggerByElementClassName, actionType, actionValue }) => {
          runOnContainerDetection(triggerByElementClassName, collection => {
            Array.from(collection).forEach(function(element) {
              element.addEventListener('click', () => {
                switch (actionType) {
                  case 'set-accordion-active-index':
                    setAccordionActiveIndex(parseInt(actionValue));
                    return;
                  case 'scroll-to-element-id':
                    const bodyRect = document.body.getBoundingClientRect();
                    const elemRect = document
                      .getElementById(`full-ingredients`)
                      ?.getBoundingClientRect();
                    setTimeout(() => {
                      window.scrollTo({
                        top: elemRect.top - bodyRect.top,
                        behavior: 'smooth'
                      });
                    }, 500);
                    return;
                  default:
                    return;
                }
              });
            });
          });
        }
      );
    }
  };

  const applyDesktopAnimation = () => {
    if (window.outerWidth < 600) {
      return;
    }
    if (!addDesktopSlideAnimation) {
      setOpen(true);
      return;
    }
    runOnContainerDetection(containerElementId, collection => {
      Array.from(collection).forEach(function(element) {
        currentElement.current = element;
        element.style.top = `calc(50% - ${containerHeight / 2 - 50}px)`;
        element.style.opacity = '0';
        element.style.transition = `all ${duration}ms`;
        Array.from(
          document.getElementsByClassName('modal-content-container')
        ).forEach(function(container) {
          container.classList.remove('hidden');
        });
        setTimeout(() => {
          element.style.top = `calc(50% - ${containerHeight / 2}px)`;
          element.style.opacity = '1';
        }, 50);
      });
    });
  };

  const applyMobileAnimation = () => {
    if (window.outerWidth > 600) {
      return;
    }
    if (!addMobileSlideAnimation) {
      setOpen(true);
      return;
    }
    runOnContainerDetection(containerElementId, collection => {
      Array.from(collection).forEach(function(element) {
        currentElement.current = element;
        element.style.marginBottom = `-${containerHeight}px`;
        element.style.transition = `margin ${duration}ms`;
        Array.from(
          document.getElementsByClassName('modal-content-container')
        ).forEach(function(container) {
          container.classList.remove('hidden');
        });
        setTimeout(() => {
          element.style.marginBottom = '0px';
        }, 50);
        element.addEventListener('touchstart', evt => {
          element.setAttribute('data-start', evt.targetTouches[0].screenY);
        });
        element.addEventListener('touchmove', evt => {
          element.setAttribute('data-moved', evt.targetTouches[0].screenY);
        });
        element.addEventListener('touchend', evt => {
          const start = element.getAttribute('data-start');
          const moved = element.getAttribute('data-moved');
          if (parseInt(moved) - parseInt(start) > 20) {
            handleClose();
          }
        });
      });
    });
  };

  const wrapperOnClick = () => {
    if (Builder.isEditing) {
      handleClose();
    }
    if (closeOnWrapperClick) {
      handleClose();
    }
  };

  const handleOpen = () => {
    setOpen(true);
    applyTrigger();
    applyMobileAnimation();
    applyDesktopAnimation();
  };

  const handleClose = () => {
    if (mobileWithAnimation || desktopWithAnimation) {
      if (mobileWithAnimation) {
        currentElement.current.style.marginBottom = `-${containerHeight}px`;
      }
      if (desktopWithAnimation) {
        currentElement.current.style.top = `calc(50% - ${containerHeight / 2 -
          50}px)`;
        currentElement.current.style.opacity = '0';
      }
      setTimeout(() => {
        setOpen(false);
      }, duration);
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <S.Trigger onClick={handleOpen}>
        {(children && children[0]) || `no Trigger component, add first child`}
      </S.Trigger>
      {open && (
        <>
          {children && children[1] && (
            <>
              {isModal ? (
                <Modal>
                  <>
                    <S.Content
                      modal={isModal}
                      className={`modal-content-container ${
                        mobileWithAnimation || desktopWithAnimation
                          ? `hidden`
                          : ``
                      }`}
                    >
                      {isModal && children && children[2] && (
                        <S.Close onClick={handleClose}>
                          {children && children[2]}
                        </S.Close>
                      )}
                      {children && children[1]}
                    </S.Content>
                    {isModal && (
                      <S.Wrapper
                        onClick={wrapperOnClick}
                        bgColor={modalWrapperBackgroundColor}
                      ></S.Wrapper>
                    )}
                  </>
                </Modal>
              ) : (
                <>
                  <S.Content modal={false}>{children && children[1]}</S.Content>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default ToggleV2;
