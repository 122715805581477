import React from 'react';
import loadable from '@loadable/component';

const ProductSelectorV1 = loadable(() => import('./ProductSelectorV1'));
const ProductSelectorV2 = loadable(() => import('./ProductSelectorV2'));
const ProductSelectorV2SubOnly = loadable(() =>
  import('./ProductSelectorV2SubOnly')
);
const ProductSelectorV3 = loadable(() => import('./ProductSelectorV3'));
const ProductSelectorV4 = loadable(() => import('./ProductSelectorV4'));
const ProductSelectorV5 = loadable(() => import('./ProductSelectorV5'));

const ProductSelector = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'version5':
      return <ProductSelectorV5 {...props} />;
    case 'version4':
      return <ProductSelectorV4 {...props} />;
    case 'version3':
      return <ProductSelectorV3 {...props} />;
    case 'version2SubOnly':
      return <ProductSelectorV2SubOnly {...props} />;
    case 'version2':
      return <ProductSelectorV2 {...props} />;
    default:
    case 'version1':
      return <ProductSelectorV1 {...props} />;
  }
};

export default ProductSelector;
