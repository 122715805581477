import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const SwipeableContainer = loadable(() => import('./SwipeableContainer'));

Builder.registerComponent(withChildren(SwipeableContainer), {
  name: 'Swipeable Container',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'horizontal',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'vertical',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'maxHorizontalSwipe',
      type: 'number',
      defaultValue: 500,
      showIf: o => o.get('horizontal')
    },
    {
      name: 'maxVerticalSwipe',
      type: 'number',
      defaultValue: 500,
      showIf: o => o.get('vertical')
    }
  ]
});
