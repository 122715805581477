import axios from 'axios';

export const updateAddress = props => {
  const { token, apiUrl, billing, shipping, billingAsShipping } = props;

  return axios.post(
    apiUrl,
    {
      token: token,
      billingAddress: billing,
      shippingAddress: shipping,
      billingAsShipping: billingAsShipping
    },
    {
      headers: { 'access-token': token }
    }
  );
};

export const updatePaymentMethod = props => {
  const {
    headerToken,
    checkoutToken,
    paymentGateway,
    apiUrl,
    billing,
    shipping,
    billingAsShipping
  } = props;

  return axios.post(
    apiUrl,
    {
      token: checkoutToken,
      payment_gateway: paymentGateway,
      billingAddress: billing,
      shippingAddress: shipping,
      billingAsShipping: billingAsShipping
    },
    {
      headers: { 'access-token': headerToken }
    }
  );
};

// Magic Link
export const updatePaymentMethod2 = props => {
  const {
    bearerToken,
    checkoutToken,
    paymentGateway,
    utmTracking,
    staging = false
  } = props;

  const endpointApiSite = staging
    ? process.env.GATSBY_ENDPOINT_API_SITE_STAGING
    : process.env.GATSBY_ENDPOINT_API_SITE;

  return axios.post(
    `${endpointApiSite}/api/card/update`,
    {
      token: checkoutToken,
      payment_gateway: paymentGateway,
      utmTracking: utmTracking
    },
    {
      headers: { Authorization: `Bearer ${bearerToken}` }
    }
  );
};

export const getUserDetails = props => {
  const { bearerToken, staging = false } = props;

  const endpointApiSite = staging
    ? process.env.GATSBY_ENDPOINT_API_SITE_STAGING
    : process.env.GATSBY_ENDPOINT_API_SITE;

  return axios
    .get(`${endpointApiSite}/api/profile`, {
      headers: { Authorization: `Bearer ${bearerToken}` }
    })
    .then(response => {
      return response.data;
    });
};

export const confirmRepurchase = props => {
  const { bearerToken, data, staging = false } = props;

  const endpointApiSite = staging
    ? process.env.GATSBY_ENDPOINT_API_SITE_STAGING
    : process.env.GATSBY_ENDPOINT_API_SITE;

  return axios.post(`${endpointApiSite}/api/add/purchase`, data, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  });
};

export const updatePaypalPayment = props => {
  const {
    bearerToken,
    staging = false,
    success_return_url = null,
    failed_return_url = null
  } = props;

  const endpointApiSite = staging
    ? process.env.GATSBY_ENDPOINT_API_SITE_STAGING
    : process.env.GATSBY_ENDPOINT_API_SITE;

  let payload = null;
  if (success_return_url || failed_return_url) {
    payload = {};
    if (success_return_url) {
      Object.assign(payload, {
        success_return_url
      });
    }
    if (failed_return_url) {
      Object.assign(payload, {
        failed_return_url
      });
    }
  }

  return axios.post(`${endpointApiSite}/api/paypal/generate-token`, payload, {
    headers: { Authorization: `Bearer ${bearerToken}` }
  });
};
