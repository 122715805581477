import axios from 'axios';

export const requestPaymentToken = props => {
  return axios.post('/.netlify/functions/payment-token', {
    ...props
  });
};

export const requestCKOPaymentToken = async props => {
  const {
    sandbox = false,
    type = 'card',
    cc_number,
    expiry_month,
    expiry_year,
    cvv
  } = props;

  const apiUrl = sandbox
    ? 'https://api.sandbox.checkout.com/tokens'
    : 'https://api.checkout.com/tokens';

  const apiKey = sandbox
    ? process.env.GATSBY_CKO_PUBLIC_KEY_SANDBOX
    : process.env.GATSBY_CKO_PUBLIC_KEY;

  return await axios.post(
    apiUrl,
    {
      type: type,
      number: cc_number,
      expiry_month: expiry_month,
      expiry_year: expiry_year,
      cvv: cvv
    },
    {
      headers: {
        Authorization: apiKey,
        'Content-Type': 'application/json'
      }
    }
  );
};
