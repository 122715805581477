import React from 'react';
import loadable from '@loadable/component';

const BumpOfferSelectorV1 = loadable(() => import('./BumpOfferSelectorV1'));
const BumpOfferSelectorV2 = loadable(() => import('./BumpOfferSelectorV2'));
const BumpOfferSelectorV3 = loadable(() => import('./BumpOfferSelectorV3'));

const BumpOfferSelector = props => {
  const { designVersion } = props;

  switch (designVersion) {
    case 'version2':
      return <BumpOfferSelectorV2 {...props} />;
    case 'version3':
      return <BumpOfferSelectorV3 {...props} />;
    default:
    case 'version1':
      return <BumpOfferSelectorV1 {...props} />;
  }
};

export default BumpOfferSelector;
