import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const TimeoutContainer = loadable(() => import('./TimeoutContainer'));

Builder.registerComponent(withChildren(TimeoutContainer), {
  name: 'Timeout Container',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  canHaveChildren: true,
  noWrap: true,
  inputs: [
    {
      name: 'duration',
      type: 'number',
      helperText: 'must be in milliseconds',
      defaultValue: 5000
    }
  ]
});
