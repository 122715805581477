import { Builder } from '@builder.io/react';
import NavLinks from './NavLinks';

Builder.registerComponent(NavLinks, {
  name: 'NavLinks',
  noWrap: true,
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1'],
      defaultValue: 'version1'
    },
    {
      name: 'navLinks',
      type: 'list',
      defaultValue: false,
      // showIf: (options) => (options.get('designVersion') === 'version2'),
      subFields: [
        { name: 'name', type: 'string', defaultValue: 'Link' },
        { name: 'link', type: 'string', defaultValue: '#Link' },
        { name: 'openInNewWindow', type: 'boolean', defaultValue: false }
      ]
    },
    {
      name: 'textColor',
      type: 'color',
      defaultValue: '#001c72',
      advanced: true
    },
    {
      name: 'textColorMobile',
      type: 'color',
      defaultValue: '#001c72',
      advanced: true
    },
    {
      name: 'burgerIconColor',
      type: 'string',
      enum: ['icon2', 'icon3'],
      defaultValue: 'icon2',
      advanced: true,
      helperText: 'icon2 - blue, icon3 - white'
    },
    {
      name: 'padding',
      type: 'string',
      defaultValue: '10px',
      advanced: true
    },
    {
      name: 'paddingMob',
      type: 'string',
      defaultValue: '10px',
      advanced: true
    },
    {
      name: 'marginTopMob',
      type: 'string',
      defaultValue: '10px',
      advanced: true
    },
    {
      name: 'marginTopMobContainer',
      type: 'string',
      defaultValue: '72px',
      advanced: true
    },
    {
      name: 'iconPosition',
      type: 'string',
      enum: ['left', 'right'],
      defaultValue: 'right',
      advanced: true
    },
    {
      name: 'iconTransform',
      type: 'string',
      enum: ['transform', 'noTransform'],
      defaultValue: 'noTransform',
      advanced: true
    }
  ]
});
