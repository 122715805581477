import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const Accordion = loadable(() => import('./Accordion'));

Builder.registerComponent(withChildren(Accordion), {
  name: 'Accordion',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'children-only'],
      defaultValue: 'version1'
    },
    {
      name: 'tickStyle',
      type: 'string',
      enum: ['plus-minus-circle', 'chevron'],
      defaultValue: 'plus-minus-circle'
    },
    {
      name: 'openedIndex',
      type: 'number',
      defaultValue: 0
    },
    {
      name: 'items',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'showTick',
          type: 'boolean',
          defaultValue: true
        },
        {
          name: 'itemId',
          type: 'string',
          defaultValue: ''
        },
        {
          name: 'bodyHeight',
          type: 'number',
          defaultValue: ''
        },
        {
          name: 'changeCategory',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'category',
          type: 'string',
          enum: ['subscriptions', 'onetime'],
          defaultValue: 'subscriptions'
        }
      ]
    },
    {
      name: 'watchForElement',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'watchForElementId',
      type: 'string',
      defaultValue: 'wait-for-me'
    },
    {
      name: 'initializeOnLoad',
      type: 'boolean',
      defaultValue: false
    }
  ]
});
