import { useContext } from 'react';
import FunnelContext from '../context/FunnelContext';

export const ProductPrice = ({
  type,
  currentCategory,
  currentProduct,
  filterByTag,
  filterBySize = false,
  productSize
}) => {
  let productData = [];

  const context = useContext(FunnelContext);

  if (currentCategory === 'subscriptions') {
    productData = context?.subscription?.products.map((product, index) => {
      product.index = index;
      return product;
    });
  } else {
    productData = context?.onetime?.products.map((product, index) => {
      product.index = index;
      return product;
    });
  }

  if (filterBySize) {
    productData = productData?.filter(({ tags }) => {
      return tags.includes(productSize);
    });
  }

  if (filterByTag) {
    productData = productData?.filter(({ tags }) => {
      return tags.includes(filterByTag);
    });
  }

  if (currentCategory === 'subscriptions') {
    productData = productData?.filter((product, i) => {
      return currentProduct === `subscription_${i}`;
    });
  } else {
    productData = productData?.filter((product, i) => {
      return currentProduct === `onetime_${i}`;
    });
  }

  const [data] = productData;

  switch (type) {
    case 'data':
      return data;
    case 'display_title':
      return data?.display_title;
    case 'quantity':
      return data?.checkoutData?.quantity ?? 1;
    case 'savings':
      return productData?.length > 0
        ? (
            (productData[0]?.checkoutData?.perceived_rrp ||
              productData[0]?.checkoutData?.price) -
            productData[0]?.checkoutData?.discounted_price
          ).toFixed(2)
        : '00.00';
    case 'discounted_price':
      return productData?.length > 0
        ? productData[0]?.checkoutData?.discounted_price.toFixed(2)
        : '00.00';
    default:
    case 'price':
      const price =
        productData[0]?.checkoutData?.perceived_rrp ||
        productData[0]?.checkoutData?.price;
      return productData?.length > 0 ? price.toFixed(2) : '00.00';
  }
};

export const CategorySavings = ({
  type = 'price',
  tub = 0,
  filterByTag = false
}) => {
  const context = useContext(FunnelContext);

  const subscription = context?.subscription?.products
    ?.filter(({ tags }) => {
      if (filterByTag) {
        return tags?.includes(filterByTag);
      }
      return true;
    })
    .filter((_, i) => {
      return `subscription_${tub}` === `subscription_${i}`;
    });

  const onetime = context?.onetime?.products
    ?.filter(({ tags }) => {
      if (filterByTag) {
        return tags?.includes(filterByTag);
      }
      return true;
    })
    .filter((_, i) => {
      return `onetime_${tub}` === `onetime_${i}`;
    });

  switch (type) {
    case 'discounted_price':
      const subs_dprice = subscription
        ? subscription[0]?.checkoutData?.discounted_price
        : null;
      const otp_dprice = onetime
        ? onetime[0]?.checkoutData?.discounted_price
        : null;
      return subs_dprice && otp_dprice
        ? (otp_dprice - subs_dprice).toFixed(2)
        : '00.00';
    default:
    case 'price':
      const subs_price = subscription
        ? subscription[0]?.checkoutData?.perceived_rrp ||
          subscription[0]?.checkoutData?.price
        : null;
      const otp_price = onetime
        ? onetime[0]?.checkoutData?.perceived_rrp ||
          onetime[0]?.checkoutData?.price
        : null;
      return subs_price && otp_price
        ? (otp_price - subs_price).toFixed(2)
        : '00.00';
  }
};

export const ProductData = ({ currentCategory, currentProduct }) => {
  let productData = [];

  const context = useContext(FunnelContext);

  if (currentCategory === 'subscriptions') {
    productData = context?.subscription?.products?.filter((product, i) => {
      return currentProduct === `subscription_${i}`;
    });
  } else {
    productData = context?.onetime?.products?.filter((product, i) => {
      return currentProduct === `onetime_${i}`;
    });
  }

  return productData;
};

export const BumpOfferData = ({ currentCategory }) => {
  let bumpOfferData = [];

  const context = useContext(FunnelContext);

  if (currentCategory === 'subscriptions') {
    bumpOfferData = context?.subscription?.bumpoffers;
  } else {
    bumpOfferData = context?.onetime?.bumpoffers;
  }

  return bumpOfferData;
};
