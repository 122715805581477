import React from 'react';
import ToggleV1 from './ToggleV1';
import ToggleV2 from './ToggleV2';

const Toggle = props => {
  const { designVersion } = props;
  switch (designVersion) {
    case 'version2':
      return <ToggleV2 {...props} />;
    default:
    case 'version1':
      return <ToggleV1 {...props} />;
  }
};

export default Toggle;
