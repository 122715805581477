import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const CategoryContainer = loadable(() => import('./CategoryContainer'));

Builder.registerComponent(withChildren(CategoryContainer), {
  name: 'Category Container',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1'],
      defaultValue: 'version1'
    },
    {
      name: 'category',
      type: 'string',
      enum: ['subscriptions', 'onetime'],
      defaultValue: 'subscriptions',
      required: true
    },
    {
      name: 'checkLocalCart',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'addTransition',
      type: 'boolean',
      defaultValue: false
    }
  ]
});
