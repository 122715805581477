import React, { useState, useEffect, useRef } from 'react';
import { Container } from './OkendoStarWidget.styles';

const OkendoStarWidget = props => {
  const {
    productId = '6645452079159',
    subscriberId = 'a6ff1c99-dd8a-4420-be09-f24d9bd7dee3'
  } = props;

  const widgetContainer = useRef(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      const okendoMetatag = document.querySelector(
        'meta[name="oke:subscriber_id"]'
      );
      if (!okendoMetatag) {
        const meta = document.createElement('meta');
        meta.name = 'oke:subscriber_id';
        meta.content = subscriberId;
        document.head.appendChild(meta);
      }

      const okendoScriptTag = document.querySelector(
        'script[id="okendo-reviews"]'
      );
      if (!window.okeWidgetApi && !okendoScriptTag) {
        const script = document.createElement('script');
        script.id = 'okendo-reviews';
        script.src =
          'https://d3hw6dc1ow8pp2.cloudfront.net/reviews-widget-plus/js/okendo-reviews.js';
        script.async = true;
        script.onload = () => {
          window.okeWidgetApi.initWidget(widgetContainer.current);
        };
        document.body.appendChild(script);
      } else {
        const interval = setInterval(() => {
          if (window.okeWidgetApi) {
            window.okeWidgetApi.initWidget(widgetContainer.current);
            clearInterval(interval);
          }
        }, 1000);
      }

      setLoaded(true);
    }
  }, [loaded, subscriberId, widgetContainer]);

  return (
    <Container
      ref={widgetContainer}
      data-oke-star-rating
      data-oke-reviews-product-id={`shopify-${productId}`}
    />
  );
};

export default OkendoStarWidget;
