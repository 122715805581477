import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                box-shadow: 0px 0px 6px #0000001A;
                border: 1px solid #E5E8F1;
            `;
      default:
        return ``;
    }
  }}

  &.rift {
    border-radius: 4px;
    border: 1px solid #ccd2e3;
    & .tabs > .sub {
      border-top-left-radius: 4px;
      border-bottom: 1px solid #ccd2e3;
      box-shadow: inset -3px 0px 3px #ccd2e3;
      &.active {
        border-right: 1px solid #ccd2e3;
        border-bottom: none;
        box-shadow: none;
      }
    }
    & .tabs > .otp {
      border-top-right-radius: 4px;
      border-top-left-radius: 0px;
      border-bottom: 1px solid #ccd2e3;
      box-shadow: inset 3px 0px 3px #ccd2e3;
      &.active {
        border-bottom: none;
        border-left: 1px solid #ccd2e3;
        box-shadow: none;
      }
    }
  }
`;

export const Tabs = styled.div`
  display: flex;
`;

export const TabItemDot = styled.span`
  border: 1px solid #ccd2e3;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: block;
  position: absolute;
  background-color: #fff;
  top: 8px;
  left: 8px;

  &.active {
    border: 1px solid #0573fb;
  }

  &.active:after {
    width: 9px;
    height: 9px;
    display: block;
    position: absolute;
    background-color: #0573fb;
    content: ' ';
    border-radius: 50%;
    top: 2px;
    left: 2px;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                top: 15px;
                left: 20px;
                width: 20px;
                height: 20px;
                &.active {
                    border: 1px solid #001C72;
                  }
                &.active:after {
                    background-color:#001C72;
                    width: 12px;
                    height: 12px;
                    top: 3px;
                    left: 3px;
                }
                @media (max-width: 375px) {
                    top: 10px;
                    left: 20px;
                    width: 16px;
                    height: 16px;
                    &.active:after {
                        width: 10px;
                        height: 10px;
                        top: 2px;
                        left: 2px;
                    }
                }
            `;
      default:
        return ``;
    }
  }};
`;

export const TabItem = styled.div`
  position: relative;
  & label {
    cursor: pointer;
    display: block;
    padding: 8px;

    & > input[type='radio'] {
      margin: 0;
      appearance: none;
      visibility: hidden;
    }
  }

  &.active {
    background: #fff;
  }

  ${({ hideSubs, hideOtp }) => {
    let styles = `
        background: #f4f4f4;
        flex: 0 0 50%;
        `;
    if (hideSubs || hideOtp) {
      styles = `
                flex: 0 0 100%;
                background: #FFF;
                & > label > input {
                    display: none;
                }
            `;
    }
    return styles;
  }}

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                    background: #E5E8F1 0% 0% no-repeat padding-box;
                    & label {
                        padding-top: 19px;
                        padding-bottom: 15px;
                        padding-left: 10px;
                        font-size: 16px;

                        @media (max-width: 375px) {
                            padding: 8px 5px;
                        }
                    }
                `;
      default:
        return ``;
    }
  }}
`;

export const TabItemLabel = styled.div``;

export const TabItemTitle = styled.span`
  font-size: 17px;
  margin-left: 18px;
  font-weight: 600;

  & > span {
    font-size: 16px;
    color: #e26447;

    ${({ pageDesign }) => {
      switch (pageDesign) {
        case 'design2':
          return `
                        color: #BC2E3E;
                        font-size: 14px;
                    `;
        default:
          return ``;
      }
    }}

    &.two-lines {
      display: block;
      margin-left: 40px;
    }
  }

  @media (max-width: 500px) {
    font-size: 14px;
    & > span {
      font-size: 12px;
    }
  }

  @media (max-width: 375px) {
    font-size: 12px;
    & > span {
      font-size: 12px;
    }
  }

  @media (max-width: 320px) {
    font-size: 12px;
    & > span {
      font-size: 12px;
      letter-spacing: 0px;
    }
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                    font-weight: 400;
                    font-size: 16px;
                    margin-left: 40px;

                    @media (max-width: 500px) {
                        font-size: 14px;
                        & > span {
                            display:block;
                            margin-left: 40px;
                        }
                    }

                `;
      default:
        return ``;
    }
  }}
`;

export const TabContent = styled.div`
  padding: 15px;
  &.free-trial {
    padding-top: 0;
  }
  @media (max-width: 500px) {
    padding: 13px;
  }
`;

export const SubsPlan = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 20px;
  background: #f8f8f8;
  padding: 8px;
`;

export const SubsPlanFirstItem = styled.div`
  display: flex;
  margin-right: 15px;
  & img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    margin-top: 3px;
  }
`;

export const SubsPlanText = styled.div`
  display: block;
  & p.small-text {
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #33498e;
  }
`;

export const SubsPlanSubTitle = styled.p`
  font-family: 'Neuzeit Grotesk - Bold', sans-serif;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
`;

export const Frequency = styled.div`
  & p {
    margin: 0;
  }
  & select {
    display: block;
    width: 100%;
    padding: 8px 16px;
    font-size: 16px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    background-color: #fff;
  }

  .frequency-select__control,
  .frequency-select__option {
    cursor: pointer;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                margin-top: 8px;
                & > label {
                    font-size: 18px;
                    margin-bottom: 11px;
                }
                & select {
                    background-color: #EEF0F7;
                    border: 1px solid #E5E8F1;
                    color: #001C72;
                }
                & .frequency-select__control {
                    color: #001C72!important;
                    background-color: #EEF0F7!important;
                   
                }
                & .frequency-select__menu {
                    color: #001C72!important;
                    background-color: #EEF0F7!important;
                   
                }
                `;
      default:
        return ``;
    }
  }}
`;

export const FrequencyLabel = styled.label`
  margin-top: 5px;
  display: flex;
`;

export const Price = styled.div`
  color: #e26447;
  font-size: 18px;
  display: block;
  white-space: nowrap;

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                    color: #001C72;
                    font-size: 20px;

                    @media (max-width: 500px) {
                        font-size: 24px;
                    }
                `;
      default:
        return ``;
    }
  }}

  & > span {
    color: #213f4e;
    text-decoration: line-through;

    ${({ pageDesign }) => {
      switch (pageDesign) {
        case 'design2':
          return `
                        color: #99A4C7;
                        font-size: 14px;
                        @media (max-width: 500px) {
                            font-size: 16px;
                        }
                    `;
        default:
          return ``;
      }
    }}
  }
`;

export const SubscriptionContent = styled.div``;

export const SubscriptionDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DiscountPercentage = styled.span`
  color: #bc2e3e;
`;

export const SubExtraInfoContent = styled.div`
  text-align: right;

  .display-title {
    font-size: 20px;
  }
`;

export const OnetimeContent = styled.div`
  padding-left: 40px;
  position: relative;
  &:before {
    content: '';
    background-repeat: no-repeat;
    height: 100px;
    width: 50px;
    position: absolute;
    top: -10px;
    left: 0;
  }
  & p {
    margin: 5px 0 0 0;
    color: #24252b;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                &:before {
                    background-image: url("/images/indicator-arrow2.svg");
                }
                & p {
                    font-size: 16px;
                    line-height: normal;
                }
                & p > .sub-price {
                    font-weight: 600;
                }
                    `;
      default:
        return `
                &:before {
                    background-image: url("/images/indicator-arrow.svg");
                }
                `;
    }
  }}
`;

export const SubPopup = styled.span`
  text-decoration: underline;
  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                    color: #BC2E3E;
                `;
      default:
        return `
                    color: #e26447;
                `;
    }
  }}
`;

export const Klarna = styled.div`
  text-align: center;
  font: normal normal normal 16px/22px Neuzeit Grotesk;
  letter-spacing: 0px;
  color: #001c72;
  background: #ffafc753 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 20px 10px;
  margin-top: 30px;
  position: relative;

  &:before {
    content: ' ';
    background: url('/images/klarna.png');
    width: 59px;
    height: 33px;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: -20px;
    left: calc(50% - 30px);
  }
`;

export const KlarnaPopup = styled.span`
  text-decoration: underline;
  font: normal normal normal 16px/22px Neuzeit Grotesk;
  letter-spacing: 0px;
  color: #bc2e3e;
  cursor: pointer;
`;
