import { Builder, withChildren } from '@builder.io/react';
import BumpOfferSelector from './BumpOfferSelector';

Builder.registerComponent(withChildren(BumpOfferSelector), {
  name: 'Bump Offer Selector',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2', 'version3'],
      defaultValue: 'version1'
    },
    {
      name: 'style',
      type: 'string',
      enum: ['default', 'rift', 'compact', 'pdp2023', 'top-checkbox', 'blue'],
      defaultValue: 'default'
    },
    {
      name: 'bumpOfferIndex',
      type: 'number',
      defaultValue: 0,
      required: false
    },
    {
      name: 'hideBumpOfferDiscount',
      type: 'boolean',
      defaultValue: false,
      required: false
    },
    {
      name: 'bumpOfferPrefixText',
      type: 'string',
      defaultValue: 'Enhance Your Chews With Our',
      required: false,
      showIf: o => o.get('designVersion') !== 'version3'
    },
    {
      name: 'bumpOfferPrefixText',
      type: 'richText',
      defaultValue: 'Enhance Your Chews With Our',
      required: false,
      showIf: o => o.get('designVersion') === 'version3'
    },
    {
      name: 'hideRibbon',
      type: 'boolean',
      defaultValue: false,
      required: false
    },
    {
      name: 'hideCheckbox',
      type: 'boolean',
      defaultValue: false,
      required: false
    },
    {
      name: 'hideRrp',
      type: 'boolean',
      defaultValue: false,
      required: false,
      showIf: o => o.get('designVersion') === 'version3'
    },
    {
      name: 'indexSettings',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'richText',
          defaultValue: 'Add another bottle for just [discounted-price]!',
          required: false
        },
        {
          name: 'prefixText',
          type: 'richText',
          defaultValue: 'Enhance Your Chews With Our',
          required: true
        },
        {
          name: 'useTemplateTags',
          type: 'boolean',
          defaultValue: false,
          required: false
        }
      ],
      showIf: o => o.get('designVersion') === 'version2'
    },
    {
      name: 'hideHeading',
      type: 'boolean',
      defaultValue: false,
      required: false,
      showIf: o => o.get('designVersion') === 'version3'
    },
    {
      name: 'cropImage',
      type: 'boolean',
      defaultValue: false,
      required: false
    },
    {
      name: 'showGreenBadge',
      type: 'boolean',
      defaultValue: false,
      required: false,
      showIf: o => o.get('designVersion') === 'version1'
    },
    {
      name: 'greenBadgeText',
      type: 'string',
      defaultValue: 'ADD TO ORDER',
      required: false,
      showIf: o => o.get('showGreenBadge')
    },
    {
      name: 'heading',
      type: 'string',
      defaultValue: false,
      required: false,
      showIf: o => !o.get('hideHeading')
    },
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'ADD TO ORDER',
      required: false,
      showIf: o => o.get('designVersion') === 'version3'
    },
    {
      name: 'buttonTextChecked',
      type: 'string',
      defaultValue: 'ADDED TO ORDER',
      required: false,
      showIf: o => o.get('designVersion') === 'version3'
    },
    {
      name: 'learnMoreType',
      type: 'string',
      required: false,
      enum: ['none', 'list', 'detailed'],
      showIf: o => o.get('designVersion') === 'version3'
    },
    {
      name: 'banner',
      type: 'file',
      required: false,
      showIf: o => o.get('learnMoreType') === 'detailed'
    },
    {
      name: 'disclaimer',
      type: 'richText',
      required: false,
      showIf: o => o.get('learnMoreType') === 'detailed'
    },
    {
      name: 'learnMoreList',
      type: 'list',
      required: false,
      subFields: [
        {
          name: 'text',
          type: 'string',
          defaultValue: '',
          required: false
        }
      ],
      showIf: o => o.get('learnMoreType') === 'list'
    }
  ]
});
