import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const MultiDogFloatingdown = loadable(() => import('./MultiDogFloatingdown'));

Builder.registerComponent(withChildren(MultiDogFloatingdown), {
  name: 'MultiDogFloatingdown',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'addDelayTime',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'delayTime',
      type: 'number',
      defaultValue: 0,
      helperText: 'in seconds',
      showIf: o => o.get('addDelayTime')
    },
    {
      name: 'showTransition',
      type: 'string',
      defaultValue: 'none',
      enum: ['none', 'slide-up']
    },
    {
      name: 'transitionSpeed',
      type: 'string',
      defaultValue: 1,
      enum: [1, 2, 3, 4, 5]
    }
  ]
});
