import React, { useContext, useEffect } from 'react';
import Modal from '../Modal/Modal';
import * as S from './UpsellPopupV2.styles';
import FunnelContext from '../../context/FunnelContext';
import AddToCartButtonV1 from '../AddToCartButton/AddToCartButtonV1';
import { ProductPrice } from '../../utils/productPrice';

export const UpsellPopupV1 = ({ children, productTitle, upsellOfferIndex }) => {
  const {
    showUpsellPopup,
    setShowUpsellPopup,
    currentCategory,
    extraObjects,
    currentProduct,
    currentTubIndex,
    currency,
    openUpsellPopupAnimation,
    setOpenUpsellPopupAnimation
  } = useContext(FunnelContext);

  const currentProductData = ProductPrice({
    type: `data`,
    currentCategory,
    currentProduct,
    tub: currentTubIndex,
    filterByTag: extraObjects?.filterProductItemsByTag || false
  });

  const handleClick = () => {
    setOpenUpsellPopupAnimation(true);
    setTimeout(() => {
      setShowUpsellPopup(false);
    }, 1000);
  };

  useEffect(() => {
    if (showUpsellPopup) {
      setOpenUpsellPopupAnimation(false);
    }
  }, [showUpsellPopup, setOpenUpsellPopupAnimation]);

  return showUpsellPopup ? (
    <Modal>
      <S.Wrapper>
        <S.Container closing={openUpsellPopupAnimation}>
          <S.PopupHeader>
            <S.PopupTitleWrapper>
              <S.PopupTitle>Added To Cart</S.PopupTitle>
              <S.CloseButton onClick={handleClick}>&times;</S.CloseButton>
            </S.PopupTitleWrapper>
            <S.PopupHeaderBody>
              <S.ProductImage>
                {currentProductData?.checkoutData.image && (
                  <img
                    src={currentProductData?.checkoutData.image}
                    alt={'displayTitle'}
                  />
                )}
                <S.QuantityCircle>
                  {currentProductData?.checkoutData?.quantity}
                </S.QuantityCircle>
              </S.ProductImage>
              <S.AddedProductTitleWrapper>
                <S.AddedProductTitle>{productTitle}</S.AddedProductTitle>
                <S.AddedProductSubTitle>
                  {currentCategory === 'subscriptions'
                    ? 'Monthly Subscription'
                    : 'One-Time Delivery'}
                </S.AddedProductSubTitle>
              </S.AddedProductTitleWrapper>
              <S.AddedProductPriceWrapper>
                <S.AddedProductDiscountPrice>
                  {currency?.symbol}
                  {currentProductData?.checkoutData?.discounted_price.toFixed(
                    2
                  )}
                </S.AddedProductDiscountPrice>
                <S.AddedProductPrice>
                  {currentCategory === 'subscriptions'
                    ? currency?.symbol +
                      currentProductData?.checkoutData?.perceived_rrp.toFixed(2)
                    : ''}
                </S.AddedProductPrice>
              </S.AddedProductPriceWrapper>
            </S.PopupHeaderBody>
          </S.PopupHeader>
          <S.Body>
            {children && children[0]}
            <S.ButtonsWrapper>
              <AddToCartButtonV1 isUpsellOffer={false}>
                <S.DeclineButton>No Thanks</S.DeclineButton>
              </AddToCartButtonV1>
              <AddToCartButtonV1
                isUpsellOffer={true}
                upsellOfferIndex={upsellOfferIndex}
              >
                <S.AcceptButton>Add To Order</S.AcceptButton>
              </AddToCartButtonV1>
            </S.ButtonsWrapper>
          </S.Body>
        </S.Container>
      </S.Wrapper>
    </Modal>
  ) : null;
};

export default UpsellPopupV1;
