import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Button = styled.div`
  cursor: pointer;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  text-decoration-line: underline;
  color: #bc2e3e;
`;
