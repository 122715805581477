import styled from 'styled-components';

export const Container = styled.div`
  color: inherit;
  @media (max-width: 640px) {
    position: absolute !important;
    width: calc(100% - 30px) !important;
    z-index: 999;
  }
`;

export const Nav = styled.div`
  @media (max-width: 640px) {
    display: none;
  }
`;

export const NavMobile = styled.div`
  display: none;
  @media (max-width: 640px) {
    display: block;
    position: fixed;
    z-index: 9999;
    top: 0;
    width: 100% !important;
    ${({ marginTopMobContainer }) =>
      `margin-top: ${marginTopMobContainer ?? `72px`};`}
    background: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const BarLine = styled.div`
  width: 22px;
  height: 3px;
  background-color: #001c72;
  margin: 4px 0;
  border-radius: 20px;
  transition: 0.6s;
  &.active-line1 {
    transform: rotate(-45deg) translate(-3px, 7px);
  }
  &.active-line2 {
    opacity: 0;
  }
  &.active-line3 {
    transform: rotate(45deg) translate(-3px, -7px);
  }
`;

export const NavLinks = styled.div`
  @media (max-width: 640px) {
    width: 100%;
    display: none;
    &.active {
      display: block;
    }
  }
`;

export const NavWrapper = styled.div`
  position: fixed;
  top: 0;
  background: #ccc;
  opacity: 20%;
  width: 100%;
  height: 100vh;
`;

export const Link = styled.a`
  text-decoration: none;

  ${({ textColor }) => {
    return `color: ${textColor ?? '#001C72'}!important;`;
  }}
  ${({ padding }) => {
    return `padding: ${padding ?? '10px'}!important;`;
  }}
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 640px) {
    width: 100%;
    display: block;
    ${({ textColorMobile }) => {
      return `color: ${textColorMobile ?? '#001C72'}!important;`;
    }}
    ${({ paddingMob }) => {
      return `padding: ${paddingMob ?? '10px'}!important;`;
    }}
  }
`;

export const MobileNav = styled.a`
  display: none;
  font-size: 25px;
  cursor: pointer;
  margin-left: auto;
  margin-top: 10px;

  ${({ textColor }) => {
    return `color: ${textColor ?? '#001C72'}!important;`;
  }};

  @media (max-width: 640px) {
    display: block;
    position: absolute;
    ${({ marginTopMob }) => {
      return `margin-top: ${marginTopMob ?? '10px'};`;
    }}
    ${({ iconTransform }) => {
      return `transform: ${
        iconTransform === 'noTransform' ? 'none;' : 'rotateX(45deg);'
      }`;
    }}
    top: 0;
    ${({ iconPosition }) => {
      return `${iconPosition}: 0`;
    }}
  }
`;
