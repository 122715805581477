import React from 'react';
import loadable from '@loadable/component';

const BumpOfferSelectorV1 = loadable(() => import('./BumpOfferScrollV1'));
const BumpOfferSelectorV2 = loadable(() => import('./BumpOfferScrollV2'));
const BumpOfferScrollPDP2023 = loadable(() =>
  import('./BumpOfferScrollPDP2023')
);
const BumpOfferScrollPDP2023v2 = loadable(() =>
  import('./BumpOfferScrollPDP2023v2')
);

const BumpOfferScroll = props => {
  const { designVersion } = props;

  switch (designVersion) {
    case 'pdp2023v2':
      return <BumpOfferScrollPDP2023v2 {...props} />;
    case 'pdp2023':
      return <BumpOfferScrollPDP2023 {...props} />;
    case 'button':
      return <BumpOfferSelectorV2 {...props} />;
    default:
    case 'tick':
      return <BumpOfferSelectorV1 {...props} />;
  }
};

export default BumpOfferScroll;
