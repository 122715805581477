import styled from 'styled-components';
import Plus from '../../../static/images/plus.inline.svg';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 300px;
  padding: 8px 12px;
  background-color: #ffffff;
  border-radius: 8px;
  ${({ withAnimation = false }) =>
    withAnimation
      ? `
    animation: UrgencyFadeInOut 14s linear 1 normal forwards;
  `
      : null}
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 12px;
`;

export const Close = styled.button`
  display: flex;
  align-items: center;
  width: 9px;
  height: 9px;
  border: none;
  text-decoration: none;
  padding: 0;
  cursor: pointer;
  background-color: inherit;
`;

export const CloseIcon = styled(Plus)`
  width: 100%;
  height: 100%;

  & > path {
    transform: rotate(45deg) translate(25%, -50%);
    fill: #001c72;
  }
`;
