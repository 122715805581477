import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  font-family: 'Neuzeit Grotesk', sans-serif;
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
  @media (max-width: 500px) {
    gap: 9px;
  }
`;

export const Button = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  padding: 14px 9px;
  margin: 18px 0;
  border: 1px solid #ccd2e3;
  border-radius: 4px;
  cursor: pointer;
  &.active {
    background: #f8eaec;
    border: 1px solid #bc2e3e;
  }
`;

export const RecommendedBadge = styled.div`
  display: grid;
  position: absolute;
  top: -10px;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 15px;
  background: #bc2e3e;
  color: #ffffff;
`;

export const ButtonTextWrapper = styled.p`
  display: grid;
  place-items: center;
  color: #001c72 !important;
  margin: 0;
  font-size: 16px;
  & span {
    color: #6677aa;
    margin-top: 8px;
  }
`;
export const ButtonTitle = styled.p`
  display: grid;
  color: #001c72;
  margin: 0;
  font-size: 16px;
`;

export const FunnelTextContainer = styled.div`
  display: grid;
  color: #6677aa;
  line-height: 20px;
  font-size: 14px;
`;

export const Label = styled.div`
  display: flex;
  margin-right: 8px;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #33498e;
`;
