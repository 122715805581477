import React from 'react';
import loadable from '@loadable/component';

const ToggleButtonV1 = loadable(() => import('./ToggleButtonV1'));

const Toggle = props => {
  const { designVersion } = props;
  switch (designVersion) {
    default:
    case 'version1':
      return <ToggleButtonV1 {...props} />;
  }
};

export default Toggle;
