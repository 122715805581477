import { useEffect } from 'react';

const useScreenResize = (callback = () => {}) => {
  useEffect(() => {
    window.addEventListener('orientationchange', callback);
    window.addEventListener('resize', callback);
    return () => {
      window.removeEventListener('orientationchange', callback);
      window.removeEventListener('resize', callback);
    };
  }, [callback]);

  return null;
};

export default useScreenResize;
