import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const UserPaymentMethod = loadable(() => import('./UserPaymentMethod'));
const NextMonthRebillDiscount = loadable(() =>
  import('./NextMonthRebillDiscount')
);

Builder.registerComponent(withChildren(UserPaymentMethod), {
  name: 'User Payment Method',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2', 'version2_direct', 'version3'],
      defaultValue: 'version1'
    },
    {
      name: 'isCheckoutComSandbox',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'updateToApi',
      type: 'boolean',
      defaultValue: false,
      showIf: o => ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'endpointApiUrl',
      type: 'string',
      defaultValue:
        'https://staging-subscription.thepetlabco.com/api/revival/billing-details',
      helperText: '{--URL--}/api/revival/billing-details',
      showIf: o =>
        o.get('updateToApi') && ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'confirmationPage',
      type: 'string',
      defaultValue: 'https://offer.thepetlabco.com/payment/update-confirmation',
      showIf: o =>
        o.get('updateToApi') && ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'expiredTokenRedirect',
      type: 'string',
      defaultValue: 'https://thepetlabco.com/pages/token-expired',
      showIf: o => ['version1', 'version2'].includes(o.get('designVersion'))
    },
    {
      name: 'nextMonthRebillDiscountTag',
      type: 'string',
      defaultValue: '',
      showIf: o => ['version2', 'version3'].includes(o.get('designVersion'))
    },
    {
      name: 'viewEvent',
      type: 'string',
      defaultValue: 'magic_link_payment_method_update_page_view',
      advanced: true
    },
    {
      name: 'updateEvent',
      type: 'string',
      defaultValue: 'magic_link_payment_method_update_selection',
      advanced: true
    },
    {
      name: 'failEvent',
      type: 'string',
      defaultValue: 'magic_link_payment_method_update_failed',
      advanced: true
    },
    {
      name: 'successEvent',
      type: 'string',
      defaultValue: 'magic_link_payment_method_update_successful',
      advanced: true
    }
  ]
});

Builder.registerComponent(NextMonthRebillDiscount, {
  name: 'Next Rebill Discount',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  noWrap: true,
  inputs: [
    {
      name: 'discountTag',
      type: 'string',
      defaultValue: ''
    }
  ]
});
