import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #dbdbdb;
  border-radius: 2px;

  &.rift {
    border-radius: 4px;
    border: 1px solid #ccd2e3;

    & .tabs > .sub {
      border-top-left-radius: 4px;
      border-bottom: 1px solid #ccd2e3;
      box-shadow: inset -3px 0px 3px #ccd2e3;

      &.active {
        border-right: 1px solid #ccd2e3;
        border-bottom: none;
        box-shadow: none;
      }
    }

    & .tabs > .otp {
      border-top-right-radius: 4px;
      border-top-left-radius: 0px;
      border-bottom: 1px solid #ccd2e3;
      box-shadow: inset 3px 0px 3px #ccd2e3;

      &.active {
        border-bottom: none;
        border-left: 1px solid #ccd2e3;
        box-shadow: none;
      }
    }
  }
`;

export const Tabs = styled.div`
  display: flex;
`;

export const TabContent = styled.div`
  padding: 15px;

  &.free-trial {
    padding-top: 0;
  }

  @media (max-width: 500px) {
    padding: 13px;
  }
`;
export const QuantityWithButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f8f8f8;
  padding: 14px 12px 14px 8px;
`;

export const SubsPlan = styled.div`
  @media (max-width: 500px) {
    display: block;
    padding: 3px;
  }
`;

export const SubsPlanItem = styled.div`
  flex: 0 0 50%;

  & p {
    margin: 0 0 0 10px;
    font-size: 12px;
  }

  @media (max-width: 500px) {
    width: 100%;
    & p {
      margin: 0;
      font-size: 14px;
    }
  }
`;

export const SubsPlanItemTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #001C72;
  margin-top: 14px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;

  & > i {
    color: #fff;
    font-size: 14px;
    background-color: #22A594;
    border-radius: 100%;
    padding: 3px 4px;
    margin-right: 8px;
    margin-top: -3px;
    display: inline-block;
    width: 20px;
    height: 20px;

    &.icon.icon-pencil::after {
      content: ' ';
      display: block;
      width: 15px;
      height: 15px;
      background-image: url('/images/check-icon-white.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    @media (max-width: 500px) {
      font-size: 16px;
    }
`;

export const SubscriptionContent = styled.div``;

export const SubscriptionDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DiscountPercentage = styled.span`
  color: #bc2e3e;
`;

export const SubExtraInfoContent = styled.div`
  text-align: right;

  .display-title {
    font-size: 20px;
  }
`;

export const OnetimeContent = styled.div`
  padding-left: 0px;
  position: relative;

  & p {
    margin: 5px 0 0 0;
  }
`;

export const SubPopup = styled.span`
  text-decoration: underline;
  cursor: pointer;

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                    color: #BC2E3E;
                `;
      default:
        return `
                    color: #e26447;
                `;
    }
  }}
`;
