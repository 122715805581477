import styled from 'styled-components';

export const TimerWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-block: 16px 10px;
  & span {
    margin-left: 4px;
  }
`;

export const TimerTitle = styled.div`
  font-weight: 700;
`;
