import { useEffect } from 'react';
import ReactDOM from 'react-dom';

const Modal = props => {
  const { children } = props;
  const el = document.createElement('div');
  const parent = document.body;
  useEffect(() => {
    parent.appendChild(el);
    parent.classList.add('no-scroll');
    return () => {
      parent.removeChild(el);
      parent.classList.remove('no-scroll');
    };
  }, [el, parent]);
  return ReactDOM.createPortal(children, el);
};

export default Modal;
