import { Builder, withChildren } from '@builder.io/react';
import Toggle from './Toggle';

Builder.registerComponent(withChildren(Toggle), {
  name: 'Toggle',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2'],
      defaultValue: 'version1'
    },
    {
      name: 'isModal',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'modalWrapperBackgroundColor',
      type: 'color',
      defaultValue: 'rgba(204,204,204,0.5)',
      showIf: o => o.get('isModal')
    },
    {
      name: 'closeOnWrapperClick',
      type: 'boolean',
      defaultValue: false,
      showIf: o => o.get('isModal')
    },
    {
      name: 'triggerBuilderElement',
      type: 'boolean',
      defaultValue: false,
      showIf: o => o.get('isModal') && o.get('designVersion') === 'version2'
    },
    {
      name: 'builderElementId',
      type: 'string',
      defaultValue: 'modal-close-trigger',
      showIf: o => o.get('triggerBuilderElement')
    },
    {
      name: 'addMobileSlideAnimation',
      type: 'boolean',
      defaultValue: false,
      showIf: o => o.get('isModal') && o.get('designVersion') === 'version2'
    },
    {
      name: 'addDesktopSlideAnimation',
      type: 'boolean',
      defaultValue: false,
      showIf: o => o.get('isModal') && o.get('designVersion') === 'version2'
    },
    {
      name: 'containerElementId',
      type: 'string',
      defaultValue: 'modal-container',
      showIf: o => o.get('addMobileSlideAnimation')
    },
    {
      name: 'containerHeight',
      type: 'number',
      defaultValue: '400',
      showIf: o => o.get('addMobileSlideAnimation')
    },
    {
      name: 'triggerElementActions',
      type: 'list',
      defaultValue: '400',
      showIf: o => o.get('triggerBuilderElement'),
      subFields: [
        {
          name: 'actionType',
          type: 'string',
          enum: [`set-accordion-active-index`, `scroll-to-element-id`],
          defaultValue: '',
          required: true
        },
        {
          name: 'triggerByElementClassName',
          type: 'string',
          defaultValue: '',
          required: true
        },
        {
          name: 'actionValue',
          type: 'string',
          defaultValue: '',
          required: true
        }
      ]
    }
  ]
});
