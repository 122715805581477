import React from 'react';
import loadable from '@loadable/component';

const LockInSelectorV1 = loadable(() => import('./LockInSelectorV1'));
const LockInSelectorV2 = loadable(() => import('./LockInSelectorV2'));
const LockInSelectorV3 = loadable(() => import('./LockInSelectorV3'));
const LockInSelectorV4 = loadable(() => import('./LockInSelectorV4'));
const LockInSelectorV5 = loadable(() => import('./LockInSelectorV5'));

const LockInSelector = props => {
  const { designVersion } = props;

  switch (designVersion) {
    case 'version5B':
      return <LockInSelectorV5 {...props} variant="B" />;
    case 'version5A':
      return <LockInSelectorV5 {...props} variant="A" />;
    case 'version4':
      return <LockInSelectorV4 {...props} />;
    case 'version3':
      return <LockInSelectorV3 {...props} />;
    case 'version2':
      return <LockInSelectorV2 {...props} />;
    default:
    case 'version1':
      return <LockInSelectorV1 {...props} />;
  }
};

export default LockInSelector;
