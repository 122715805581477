import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const AnswerSelect = loadable(() => import('./AnswerSelect'));

Builder.registerComponent(AnswerSelect, {
  name: 'Slides:AnswerSelect',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2'],
      defaultValue: 'version1'
    },
    {
      name: 'type',
      type: 'string',
      required: true,
      enum: ['normal', 'breeds', 'months', 'years', 'range', 'breeds2'],
      defaultValue: 'normal',
      helperText: 'object key'
    },
    {
      name: 'name',
      type: 'string',
      required: true,
      defaultValue: 'unique_answer_key',
      helperText: 'object key'
    },
    {
      name: 'placeholder',
      type: 'string',
      defaultValue: 'Hello there',
      helperText: 'Text to display when there is no value'
    },
    // TODO: handle value vs default value automatically like ng-model
    {
      name: 'options',
      type: 'list',
      defaultValue: [{ value: 'hello', title: 'Title' }],
      subFields: [
        {
          name: 'value',
          type: 'string',
          defaultValue: 'value',
          required: true
        },
        {
          name: 'label',
          type: 'string',
          defaultValue: 'Label',
          required: true
        }
      ],
      showIf: options => options.get('type') === 'normal'
    },
    {
      name: 'range_start',
      type: 'number',
      required: false,
      defaultValue: 0,
      showIf: options => options.get('type') === 'range'
    },
    {
      name: 'range_end',
      type: 'number',
      required: false,
      defaultValue: 10,
      showIf: options => options.get('type') === 'range'
    },
    {
      name: 'isFullWidth',
      type: 'boolean',
      defaultValue: false,
      helperText: 'If enabled - select input control would fill 100% width'
    },
    {
      name: 'showMenuOnFocus',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'filterByPetType',
      type: 'string',
      defaultValue: 'dog',
      enum: ['dog', 'cat'],
      showIf: options => options.get('type') === 'breeds2'
    }
  ],
  noWrap: true,
  static: true,
  defaultStyles: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '3px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#ccc',
    flexDirection: 'row'
  }
});
