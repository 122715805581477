import { Builder, withChildren } from '@builder.io/react';
import UpsellPopup from './UpsellPopup';

Builder.registerComponent(withChildren(UpsellPopup), {
  name: 'UpsellPopup',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2'],
      defaultValue: 'version1'
    },
    {
      name: 'timerSeconds',
      type: 'number',
      defaultValue: 120,
      showIf: o => o.get('designVersion') === 'version1'
    },
    {
      name: 'productTitle',
      type: 'string',
      defaultValue: 'product name',
      showIf: o => o.get('designVersion') === 'version2'
    },
    {
      name: 'upsellOfferIndex',
      type: 'number',
      defaultValue: 0
    }
  ]
});
