import React, { useState } from 'react';
import * as S from './ShowHide.styles';

function ShowHide({ buttonOpenText, buttonCloseText, children }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <S.ButtonWrapper>
        <S.Button onClick={() => setOpen(!open)}>
          {open ? buttonCloseText : buttonOpenText}
        </S.Button>
      </S.ButtonWrapper>
      {open && <>{children}</>}
    </>
  );
}

export default ShowHide;
