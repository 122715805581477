import React, { useContext } from 'react';
import * as S from './ProductTitleV2.styles';
import FunnelContext from '../../context/FunnelContext';

const ProductTitle = props => {
  const { name, headTitle, showHeadTitle = true, titleList } = props;

  const context = useContext(FunnelContext);
  const { currentCategory, pageDesign, currentTubIndex } = context;

  const currentCategoryDefault = currentCategory ?? 'subscriptions';

  const SubTitle = ({ subsTitle, otpTitle }) => {
    const { hideSubTitle } = (titleList && titleList[currentTubIndex]) || {};
    if (hideSubTitle) return null;

    if (currentCategoryDefault === `subscriptions`) {
      return subsTitle ? (
        <S.SubsTitle pageDesign={pageDesign}>{subsTitle}</S.SubsTitle>
      ) : (
        ''
      );
    } else {
      return otpTitle ? (
        <S.SubsTitle pageDesign={pageDesign}>{otpTitle}</S.SubsTitle>
      ) : (
        ''
      );
    }
  };

  return (
    <S.Container>
      {showHeadTitle && (
        <S.TopTitle className="head-title" pageDesign={pageDesign}>
          {headTitle && headTitle !== `` ? headTitle : `BESTSELLER`}
        </S.TopTitle>
      )}
      <S.Name pageDesign={pageDesign}>
        {titleList ? titleList[currentTubIndex].name : name}{' '}
        <SubTitle {...props} />
      </S.Name>
    </S.Container>
  );
};

export default ProductTitle;
