import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const CampaignContainer = loadable(() => import('./CampaignContainer'));

Builder.registerComponent(withChildren(CampaignContainer), {
  name: 'Campaign Container',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'campaignId',
      type: 'string',
      enum: [
        'DEFAULT',
        'HALLOWEEN',
        'XMAS',
        'NEWYEAR',
        'BLACKFRIDAY',
        'CYBERMONDAY',
        'FOURTHOFJULY',
        'VALENTINES',
        'EVERGREEN'
      ],
      defaultValue: 'DEFAULT',
      required: true
    }
  ]
});
