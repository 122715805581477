import React from 'react';
import loadable from '@loadable/component';

const CategorySelectorV1 = loadable(() => import('./CategorySelectorV1'));
const CategorySelectorV2 = loadable(() => import('./CategorySelectorV2'));
const ChildrenOnly = loadable(() => import('./ChildrenOnly'));

const CategorySelector = props => {
  const { designVersion } = props;

  switch (designVersion) {
    case 'children-only':
      return <ChildrenOnly {...props} />;
    case 'version2':
      return <CategorySelectorV2 {...props} />;
    default:
    case 'version1':
      return <CategorySelectorV1 {...props} />;
  }
};

export default CategorySelector;
