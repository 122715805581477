import { useCallback, useEffect } from 'react';

const useButtonEventPressed = (trigger, duration = 500) => {
  const itemMouseDown = useCallback(element => {
    element.classList.add('pressed');
  }, []);

  const itemMouseUp = useCallback(
    element => {
      setTimeout(() => {
        element.classList.remove('pressed');
      }, duration);
    },
    [duration]
  );

  useEffect(() => {
    Array.from(document.getElementsByClassName(trigger)).forEach(function(
      element
    ) {
      element.addEventListener('mousedown', () => itemMouseDown(element));
      element.addEventListener('mouseup', () => itemMouseUp(element));
      element.addEventListener('mouseout', () => itemMouseUp(element));
    });
    return () => {
      Array.from(document.getElementsByClassName(trigger)).forEach(function(
        element
      ) {
        element.removeEventListener('mousedown', () => itemMouseDown(element));
        element.removeEventListener('mouseup', () => itemMouseUp(element));
        element.removeEventListener('mouseout', () => itemMouseUp(element));
      });
    };
  }, [trigger, itemMouseDown, itemMouseUp]);
};

export default useButtonEventPressed;
