import { Builder, withChildren } from '@builder.io/react';
import UrgencyTag from './UrgencyTag';

Builder.registerComponent(withChildren(UrgencyTag), {
  name: 'Urgency Tag',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'urgencyTagIcon',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg']
    },
    {
      name: 'urgencyTagText',
      type: 'richText',
      defaultValue: ''
    },
    {
      name: 'withAnimation',
      type: 'boolean',
      defaultValue: false
    }
  ]
});
