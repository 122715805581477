import { Builder, withChildren } from '@builder.io/react';
import BumpOfferScroll from './BumpOfferScroll';

Builder.registerComponent(withChildren(BumpOfferScroll), {
  name: 'Bump Offer Scroll',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['tick', 'button', 'pdp2023', 'pdp2023v2'],
      defaultValue: 'tick'
    },
    {
      name: 'styles',
      type: 'string',
      enum: ['default', 'styles1'],
      defaultValue: 'tick'
    },
    {
      name: 'mobileCards',
      type: 'string',
      enum: ['default', 'slim'],
      defaultValue: 'default'
    },
    {
      name: 'showTitle',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'otpTitle',
      type: 'string',
      defaultValue: `3. Add Extras`,
      required: false,
      showIf: o => o.get('showTitle') === true
    },
    {
      name: 'subTitle',
      type: 'string',
      defaultValue: `3. Add extras to your subscription`,
      required: false,
      showIf: o => o.get('showTitle') === true
    },
    {
      name: 'offers',
      type: 'list',
      subFields: [
        {
          name: 'popupTitle',
          type: 'richText',
          defaultValue: `Pair your order with [display-title] for the perfect bundle!`,
          helperText: `Template Tags: [display-title]`
        },
        {
          name: 'images',
          type: 'list',
          defaultValue: [],
          subFields: [
            {
              name: 'image',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
              required: true,
              defaultValue:
                'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
            },
            {
              name: 'alt',
              type: 'string',
              defaultValue: 'Image Item'
            },
            {
              name: 'video',
              type: 'boolean',
              defaultValue: true
            },
            {
              name: 'videoProps',
              type: 'object',
              subFields: [
                {
                  name: 'vidalyticsId',
                  type: 'string',
                  defaultValue: ''
                },
                {
                  name: 'vidalyticsUrl',
                  type: 'string',
                  defaultValue: ''
                },
                {
                  name: 'maxWidth',
                  type: 'number',
                  defaultValue: 1150
                }
              ]
            }
          ]
        },
        {
          name: 'description',
          type: 'richText',
          defaultValue: `PetLab Co.’s [display-title] has been formulated with carefully selected ingredients. There are no harmful chemicals in the [display-title] solution, which means it’s safe for everyday use. `,
          helperText: `Template Tags: [display-title]`
        },
        {
          name: 'keyPoints',
          type: 'list',
          subFields: [
            {
              name: 'point',
              type: 'longText',
              defaultValue: ''
            }
          ]
        },
        {
          name: 'ingredients',
          type: 'longText',
          defaultValue: ''
        },
        {
          name: 'backgroundColor',
          type: 'color',
          required: false
        },
        {
          name: 'mainImage',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          defaultValue:
            'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d'
        },
        {
          name: 'shortDescription',
          type: 'string',
          defaultValue: `Short description here`
        }
      ]
    },
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'ADD',
      required: false,
      showIf: o => o.get('designVersion') === 'button'
    },
    {
      name: 'buttonTextChecked',
      type: 'string',
      defaultValue: 'REMOVE',
      required: false,
      showIf: o => o.get('designVersion') === 'button'
    },
    {
      name: 'popupButtonText',
      type: 'string',
      defaultValue: 'PAIR ITEM',
      required: false
    },
    {
      name: 'popupButtonTextChecked',
      type: 'string',
      defaultValue: 'REMOVE ITEM',
      required: false
    },
    {
      name: 'oneTimeText',
      type: 'string',
      defaultValue: 'This item will only be delivered one time.',
      required: false
    },
    {
      name: 'showSaleBadge',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'showDiscountPercentage',
      type: 'boolean',
      defaultValue: true
    },
    {
      name: 'saleBadgeText',
      type: 'string',
      defaultValue: 'Sale',
      required: false
    },
    {
      name: 'listIcon',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      advanced: true
    },
    {
      name: 'width',
      type: 'number',
      advanced: true
    },
    {
      name: 'mobileWidth',
      type: 'number',
      advanced: true
    },
    {
      name: 'forceCurrentCategory',
      type: 'boolean',
      defaultValue: false,
      advanced: true
    },
    {
      name: 'forceCurrentCategoryValue',
      type: 'string',
      enum: ['onetime', 'subscriptions'],
      defaultValue: 'subscriptions',
      advanced: true
    },
    {
      name: 'minHeightDesktop',
      type: 'number',
      advanced: true
    },
    {
      name: 'minHeightMobile',
      type: 'number',
      advanced: true
    },
    {
      name: 'carouselContainerPercentage',
      type: 'number',
      advanced: true
    }
  ]
});
