import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as S from './PackageSelectorV4.styles';
import FunnelContext from '../../context/FunnelContext';
import {
  CategorySavings,
  ProductData,
  ProductPrice
} from '../../utils/productPrice';
import { calculateDiscount } from '../../utils/calculateDiscount';
import useLocalStorage from '../../hooks/useLocalStorage';
import loadable from '@loadable/component';
import { formatPrice } from '../../utils/formatPrice';
import QuantitySelectTabs from './QuantitySelectTabs/QuantitySelectTabs';

const PriceComparison = loadable(() =>
  import('./PriceComparison/PriceComparison')
);
const FrequencySelect = loadable(() =>
  import('./FrequencySelect/FrequencySelect')
);
const SubExtraInfo = loadable(() => import('./SubExtraInfo/SubExtraInfo'));
const Savings = loadable(() => import('./Savings/Savings'));
const SubsTabItem = loadable(() => import('./SubTabItem/SubTabItem'));
const OtpTabItem = loadable(() => import('./OtpTabItem/OtpTabItem'));
const SubPopup = loadable(() => import('./SubPopupLegacy/SubPopupLegacy'));
const SubLockInSelector = loadable(() =>
  import('./SubLockInSelector/SubLockInSelector')
);
const QuantitySelect = loadable(() =>
  import('./QuantitySelect/QuantitySelect')
);

const PackageSelector = props => {
  const {
    children,
    subscriptionSchedules,
    faqURL,
    showPriceComparison,
    invertPriceComparison,
    hideSubsTab,
    hideOtpTab,
    useSelectComponent,
    showKlarna,
    otpKlarna,
    subKlarna,
    withFreeGift,
    subLockInEnabled,
    subLockInPeriods,
    currencySymbolToEnd,
    commaDecimalPoint,
    tabTwoLines,
    hideTabSavings,
    hideMainChild,
    useChildrenInTabContent,
    tabContentChildIndexForSub,
    tabContentChildIndexForOtp,
    language,
    hideSubsSavings,
    hideOtpSavings,
    hideParenthesesSavings,
    subSavingsAddon,
    otpSavingsAddon,
    subscriptionInfo,
    percentageSavings,
    style = `default`,
    hideFrequencySelector = false,
    showQuantitySelector = false,
    showQuantitySelectorForOtp,
    showPriceComparisonColumn = false,
    hideQuantitySelectorItems = false,
    quantitySelectorItems = [],
    quantitySelectorLabel = `Select Quantity`,
    addPerMonthTextOnSubPrices = false,
    useQuantityTabs = false,
    quantityLabelSmartText = '',
    percentageSavingsTubTitle = false,
    addCustomTextOnSubPrices = '',
    popupLink = false
  } = props;

  const context = useContext(FunnelContext);
  const {
    currency,
    currentCategory,
    setCurrentCategory,
    currentProduct,
    setCurrentProduct,
    currentTubIndex,
    pageDesign,
    extraObjects,
    setExtraObjects
  } = context;
  const productDataSub = ProductData({
    currentCategory: 'subscriptions',
    currentProduct: `subscription_${currentTubIndex}`,
    filterByTag: extraObjects?.filterProductItemsByTag || false
  });

  const priceSettings = {
    currencySymbol: currency?.symbol,
    currencySymbolToEnd,
    commaDecimalPoint
  };

  const [showSubPopup, setShowSubPopup] = useState(false);
  const [checkTabs, setCheckTabs] = useState(false);
  const [, setExtraObjects2] = useLocalStorage('extraObjects');

  const initKlarna = useCallback(
    currentCategory => {
      if (showKlarna) {
        if (currentCategory === `subscriptions`) {
          setExtraObjects(
            Object.assign(extraObjects, {
              klarna_enabled: subKlarna ? '1' : '0'
            })
          );
          setExtraObjects2(
            Object.assign(extraObjects, {
              klarna_enabled: subKlarna ? '1' : '0'
            })
          );
        } else if (currentCategory === `onetime`) {
          setExtraObjects(
            Object.assign(extraObjects, {
              klarna_enabled: otpKlarna ? '1' : '0'
            })
          );
          setExtraObjects2(
            Object.assign(extraObjects, {
              klarna_enabled: otpKlarna ? '1' : '0'
            })
          );
        }
      }
    },
    [
      extraObjects,
      setExtraObjects,
      showKlarna,
      otpKlarna,
      subKlarna,
      setExtraObjects2
    ]
  );

  useEffect(() => {
    if (!checkTabs) {
      if (hideSubsTab) {
        setCurrentCategory('onetime');
        setCurrentProduct(`onetime_${currentTubIndex}`);
      }
      if (hideOtpTab) {
        setCurrentCategory('subscriptions');
        setCurrentProduct(`subscription_${currentTubIndex}`);
      }

      setExtraObjects(
        Object.assign(extraObjects, {
          currentCategory
        })
      );
      setExtraObjects2(
        Object.assign(extraObjects, {
          currentCategory
        })
      );

      initKlarna(currentCategory || 'subscriptions');

      return () => setCheckTabs(true);
    }
  }, [
    hideSubsTab,
    hideOtpTab,
    checkTabs,
    setCheckTabs,
    currentTubIndex,
    currentCategory,
    setCurrentCategory,
    setCurrentProduct,
    extraObjects,
    setExtraObjects,
    showKlarna,
    otpKlarna,
    subKlarna,
    initKlarna,
    setExtraObjects2
  ]);

  const TabContent = () => {
    const discountedPrice = ProductPrice({
      type: `discounted_price`,
      tub: currentTubIndex,
      currentCategory: 'subscriptions',
      currentProduct: `subscription_${currentTubIndex}`,
      filterByTag: extraObjects?.filterProductItemsByTag || false
    });
    const price = ProductPrice({
      type: `price`,
      tub: currentTubIndex,
      currentCategory: currentCategory,
      currentProduct: currentProduct,
      filterByTag: extraObjects?.filterProductItemsByTag || false
    });

    if (currentCategory === `subscriptions`) {
      const langBenefitOneTitle =
        language?.benefitOneTitle ?? `Flexible subscription plan`;
      const langBenefitTwoTitle = language?.benefitTwoTitle ?? `Easy Cancel`;
      const langBenefitThreeTitle =
        language?.benefitThreeTitle ??
        `FREE Salmon Bites gift with first delivery`;
      return (
        <S.SubscriptionContent className="sub-content">
          {useQuantityTabs ? (
            <>
              <PriceComparison
                showPriceComparison={showPriceComparison}
                invertPriceComparison={invertPriceComparison}
                showPriceComparisonColumn={showPriceComparisonColumn}
                priceSettings={priceSettings}
                addPerMonthTextOnSubPrices={addPerMonthTextOnSubPrices}
                currentProduct={currentProduct}
                addCustomTextOnSubPrices={addCustomTextOnSubPrices}
              />
              <QuantitySelectTabs
                items={quantitySelectorItems}
                label={quantitySelectorLabel}
                useSelectComponent={useSelectComponent}
                hideItems={hideQuantitySelectorItems}
                quantityLabelSmartText={quantityLabelSmartText}
              />
            </>
          ) : (
            <>
              <S.SubscriptionDetails>
                <div>
                  <PriceComparison
                    showPriceComparison={showPriceComparison}
                    invertPriceComparison={invertPriceComparison}
                    showPriceComparisonColumn={showPriceComparisonColumn}
                    priceSettings={priceSettings}
                    addPerMonthTextOnSubPrices={addPerMonthTextOnSubPrices}
                    currentProduct={currentProduct}
                    addCustomTextOnSubPrices={addCustomTextOnSubPrices}
                  />
                  {percentageSavings && (
                    <S.DiscountPercentage>
                      <Savings
                        currentCategory="subscriptions"
                        currentProduct={`subscription_${currentTubIndex}`}
                        addon={subSavingsAddon}
                        hideParenthesesSavings={hideParenthesesSavings}
                        hideTabSavings={hideTabSavings}
                        tabTwoLines={tabTwoLines}
                        priceSettings={priceSettings}
                        language={language}
                      />
                      <span>{` `}</span>
                      <span>
                        {`(${calculateDiscount(price, discountedPrice)}% OFF)`}
                      </span>
                    </S.DiscountPercentage>
                  )}
                </div>
                {subscriptionInfo && <SubExtraInfo />}
              </S.SubscriptionDetails>
              {!hideFrequencySelector && subscriptionSchedules?.length > 0 && (
                <FrequencySelect
                  useSelectComponent={useSelectComponent}
                  subscriptionSchedules={subscriptionSchedules}
                  language={language}
                />
              )}
              {showQuantitySelector && quantitySelectorItems?.length > 0 && (
                <QuantitySelect
                  items={quantitySelectorItems}
                  useSelectComponent={useSelectComponent}
                  label={quantitySelectorLabel}
                  hideItems={hideQuantitySelectorItems}
                />
              )}
            </>
          )}
          <SubLockInSelector show={subLockInEnabled} items={subLockInPeriods} />
          <S.SubsPlan className="sub-plan">
            <S.SubsPlanItem>
              <S.SubsPlanItemTitle>
                <i className="icon icon-pencil"></i>
                {langBenefitOneTitle}
              </S.SubsPlanItemTitle>
            </S.SubsPlanItem>
            <S.SubsPlanItem>
              <S.SubsPlanItemTitle>
                <i className="icon icon-pencil"></i>
                {popupLink ? (
                  <S.SubPopup
                    pageDesign={pageDesign}
                    onClick={() => setShowSubPopup(true)}
                  >
                    {langBenefitTwoTitle}
                  </S.SubPopup>
                ) : (
                  langBenefitTwoTitle
                )}
              </S.SubsPlanItemTitle>
            </S.SubsPlanItem>
            <S.SubsPlanItem>
              <S.SubsPlanItemTitle>
                <i className="icon icon-pencil"></i>
                {langBenefitThreeTitle}
              </S.SubsPlanItemTitle>
            </S.SubsPlanItem>
          </S.SubsPlan>
        </S.SubscriptionContent>
      );
    } else {
      const onetimeInnerHtml = () => {
        let withFreeGiftText = language?.withFreeGift ?? `+ A FREE Gift`;
        let langOnetimeContent =
          language?.onetimeContent ??
          `You can have the same for only <strong>[sub-price] [free-gift]</strong> if you subscribe. That’s a saving of [savings] and you can change or cancel your subscription! 😱😱😱`;

        const CategorySavingsAmount = CategorySavings({
          type: 'discounted_price',
          tub: currentTubIndex,
          filterByTag: extraObjects?.filterProductItemsByTag || false
        });
        langOnetimeContent = langOnetimeContent?.replace(
          /\[savings\]/gm,
          formatPrice(CategorySavingsAmount, priceSettings)
        );
        langOnetimeContent = langOnetimeContent?.replace(
          /\[sub-price\]/gm,
          formatPrice(discountedPrice, priceSettings)
        );
        langOnetimeContent = langOnetimeContent?.replace(
          /\[currency-symbol\]/gm,
          priceSettings.currencySymbol
        );
        langOnetimeContent = langOnetimeContent?.replace(
          /\[free-gift\]/gm,
          withFreeGift && productDataSub[0]?.freeGiftData?.variantId
            ? withFreeGiftText
            : ''
        );

        return {
          __html: langOnetimeContent
        };
      };
      return (
        <>
          <S.OnetimeContent pageDesign={pageDesign} className="otp-content">
            {useQuantityTabs ? (
              <>
                <PriceComparison
                  showPriceComparison={showPriceComparison}
                  invertPriceComparison={invertPriceComparison}
                  priceSettings={priceSettings}
                  addPerMonthTextOnSubPrices={addPerMonthTextOnSubPrices}
                />
                <QuantitySelectTabs
                  items={quantitySelectorItems}
                  label={quantitySelectorLabel}
                  useSelectComponent={useSelectComponent}
                  quantityLabelSmartText={quantityLabelSmartText}
                  hideItems={hideQuantitySelectorItems}
                />
              </>
            ) : (
              <PriceComparison
                showPriceComparison={showPriceComparison}
                invertPriceComparison={invertPriceComparison}
                priceSettings={priceSettings}
                addPerMonthTextOnSubPrices={addPerMonthTextOnSubPrices}
              />
            )}
            <p dangerouslySetInnerHTML={onetimeInnerHtml()} />
          </S.OnetimeContent>
          {showQuantitySelectorForOtp && quantitySelectorItems?.length > 0 && (
            <QuantitySelect
              items={quantitySelectorItems}
              useSelectComponent={useSelectComponent}
              label={quantitySelectorLabel}
              hideItems={hideQuantitySelectorItems}
            />
          )}
        </>
      );
    }
  };
  const TabContentChildren = () =>
    currentCategory === `subscriptions` ? (
      <>{children[tabContentChildIndexForSub || 0]}</>
    ) : (
      <>{children[tabContentChildIndexForOtp || 0]}</>
    );

  return (
    <S.Container
      pageDesign={pageDesign}
      className={`package-selector ${style}`}
    >
      <S.Tabs className="tabs">
        <SubsTabItem
          hideSubsTab={hideSubsTab}
          hideParenthesesSavings={hideParenthesesSavings}
          tabTwoLines={tabTwoLines}
          hideTabSavings={hideTabSavings}
          priceSettings={priceSettings}
          initKlarna={initKlarna}
          hideOtpTab={hideOtpTab}
          language={language}
          hideSubsSavings={hideSubsSavings}
          subSavingsAddon={subSavingsAddon}
          blockId={props.builderBlock.id}
          percentageSavingsTubTitle={percentageSavingsTubTitle}
          style={style}
        />
        <OtpTabItem
          hideSubsTab={hideSubsTab}
          initKlarna={initKlarna}
          hideOtpTab={hideOtpTab}
          language={language}
          hideOtpSavings={hideOtpSavings}
          otpSavingsAddon={otpSavingsAddon}
          blockId={props.builderBlock.id}
          style={style}
        />
      </S.Tabs>

      <S.TabContent className="tab-content">
        {!hideMainChild && <>{children}</>}
        {useChildrenInTabContent ? <TabContentChildren /> : <TabContent />}
      </S.TabContent>

      <SubPopup
        version={language?.benefitTwoPopupVersion ?? 'english'}
        show={showSubPopup}
        setShowSubPopup={setShowSubPopup}
        faqURL={faqURL}
      />
    </S.Container>
  );
};

export default PackageSelector;
