import React, { useContext, useEffect } from 'react';
import Modal from '../Modal/Modal';
import * as S from './UpsellPopupV1.styles';
import FunnelContext from '../../context/FunnelContext';
import AddToCartButtonV1 from '../AddToCartButton/AddToCartButtonV1';
import Timer from './Timer/Timer';

export const UpsellPopupV1 = ({ children, timerSeconds, upsellOfferIndex }) => {
  const {
    showUpsellPopup,
    setShowUpsellPopup,
    openUpsellPopupAnimation,
    setOpenUpsellPopupAnimation
  } = useContext(FunnelContext);

  const buttonRef = React.useRef(null);

  const simulateClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };
  const handleTimerFinish = () => {
    simulateClick();
  };

  const handleClick = () => {
    setOpenUpsellPopupAnimation(true);
    setTimeout(() => {
      setShowUpsellPopup(false);
    }, 1000);
  };

  useEffect(() => {
    if (showUpsellPopup) {
      setOpenUpsellPopupAnimation(false);
    }
  }, [showUpsellPopup, setOpenUpsellPopupAnimation]);

  return showUpsellPopup ? (
    <Modal>
      <S.Wrapper>
        <S.Container closing={openUpsellPopupAnimation}>
          <S.Body>
            <S.CloseButton onClick={handleClick}>
              <span>&times;</span>
            </S.CloseButton>
            {children && children[0]}
            <Timer
              onTimerFinish={handleTimerFinish}
              timerSeconds={timerSeconds}
            />
            <S.ButtonsWrapper>
              <AddToCartButtonV1 isUpsellOffer={false}>
                <S.DeclineButton ref={buttonRef}>No Thanks</S.DeclineButton>
              </AddToCartButtonV1>
              <AddToCartButtonV1
                isUpsellOffer={true}
                upsellOfferIndex={upsellOfferIndex}
              >
                <S.AcceptButton>Add To My Order Now</S.AcceptButton>
              </AddToCartButtonV1>
            </S.ButtonsWrapper>
          </S.Body>
        </S.Container>
      </S.Wrapper>
    </Modal>
  ) : null;
};

export default UpsellPopupV1;
