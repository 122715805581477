import { Builder } from '@builder.io/react';
import { timezones } from '../../utils/datetime';
import loadable from '@loadable/component';

const CountDown = loadable(() => import('./CountDown'));

Builder.registerComponent(CountDown, {
  name: 'Countdown Timer',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'design',
      type: 'string',
      enum: ['design1', 'halloween', 'black-friday', 'christmas-2023'],
      defaultValue: 'design1'
    },
    {
      name: 'format',
      type: 'string',
      enum: [
        'DD:HH:MM:SS',
        'DD:HH:MM:SS-WITH-TEXT',
        'DD:HH:MM',
        'HH:MM:SS',
        'HH:MM',
        'MM:SS',
        'CUSTOM-TEXT'
      ],
      defaultValue: 'HH:MM:SS'
    },
    {
      name: 'dayTitle',
      type: 'string',
      showIf: o => o.get('format') === 'DD:HH:MM:SS-WITH-TEXT'
    },
    {
      name: 'hourTitle',
      type: 'string',
      showIf: o => o.get('format') === 'DD:HH:MM:SS-WITH-TEXT'
    },
    {
      name: 'minTitle',
      type: 'string',
      showIf: o => o.get('format') === 'DD:HH:MM:SS-WITH-TEXT'
    },
    {
      name: 'secTitle',
      type: 'string',
      showIf: o => o.get('format') === 'DD:HH:MM:SS-WITH-TEXT'
    },
    {
      name: 'customText',
      type: 'richText',
      defaultValue:
        '[DD] [DDLABEL] [HH] [HHLABEL] [MM] [MMLABEL] [SS] [SSLABEL]',
      helperText:
        'Template Tags: [DD] [HH] [MM] [SS] [DDLABEL] [HHLABEL] [MMLABEL] [SSLABEL]',
      showIf: o => o.get('format') === 'CUSTOM-TEXT'
    },
    {
      name: 'endMonth',
      type: 'string',
      enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      defaultValue: '1'
    },
    {
      name: 'endDay',
      type: 'string',
      enum: Array.from(Array(32).keys()).filter(n => n !== 0),
      defaultValue: '1'
    },
    {
      name: 'endYear',
      type: 'string',
      defaultValue: '2021'
    },
    {
      name: 'endHour',
      type: 'string',
      enum: Array.from(Array(24).keys()),
      defaultValue: '0'
    },
    {
      name: 'endMinutes',
      type: 'string',
      enum: Array.from(Array(60).keys()),
      defaultValue: '0'
    },
    {
      name: 'endTimezone',
      type: 'string',
      enum: timezones,
      defaultValue: 'GMT+0'
    },
    {
      name: 'timeoutString',
      type: 'string',
      defaultValue: 'Promo Ended'
    }
  ]
});
