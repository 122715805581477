import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const SizeSelector = loadable(() => import('./SizeSelector'));

Builder.registerComponent(withChildren(SizeSelector), {
  name: 'Size Selector',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'title',
      type: 'string',
      value: 'Select Your Dog’s Size'
    },
    {
      name: 'sizes',
      type: 'list',
      subFields: [
        {
          name: 'id',
          type: 'string',
          defaultValue: 'small',
          required: true
        },
        {
          name: 'title',
          type: 'richText',
          defaultValue: '<strong>Small Dog</strong> (under 25 lbs)',
          required: true
        },
        {
          name: 'description',
          type: 'richText',
          defaultValue: '1 Tub monthly / 1 chew daily',
          required: true
        },
        {
          name: 'icon',
          type: 'file',
          allowedFileTypes: ['jpeg', 'png', 'svg']
        },
        {
          name: 'iconWidth',
          type: 'number'
        },
        {
          name: 'iconHeight',
          type: 'number'
        },
        {
          name: 'defaultSize',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'setCurrentTubIndex',
          type: 'boolean',
          defaultValue: false
        },
        {
          name: 'currentTubIndex',
          type: 'number',
          defaultValue: 0
        }
      ]
    }
  ]
});
