import { Builder, withChildren } from '@builder.io/react';
import loadable from '@loadable/component';

const ActiveSubscriptionSelector = loadable(() =>
  import('./ActiveSubscriptionSelector')
);

Builder.registerComponent(withChildren(ActiveSubscriptionSelector), {
  name: 'Active Subscription Selector',
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2', 'version3'],
      defaultValue: 'version1'
    },
    {
      name: 'styles',
      type: 'string',
      enum: ['default', 'pdp2023'],
      defaultValue: 'default'
    },
    {
      name: 'expiredTokenRedirect',
      type: 'string',
      defaultValue: 'https://thepetlabco.com/pages/token-expired',
      showIf: o =>
        ['version1', 'version2', 'version3'].includes(o.get('designVersion'))
    },
    {
      name: 'endpointApiUrl',
      type: 'string',
      defaultValue:
        'https://staging-subscription.thepetlabco.com/api/crossell/subscriptions',
      helperText: `Staging URL: https://staging-subscription.thepetlabco.com/api/crossell/subscriptions
      Live URL: https://subscription.altitudecheckout.com/api/crossell/subscriptions
      `,
      showIf: o => ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'specialOffer',
      type: 'boolean',
      defaultValue: false,
      showIf: o => ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'sendDiscountCode',
      type: 'boolean',
      defaultValue: false,
      showIf: o => ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'discountType',
      type: 'string',
      enum: [
        'DISCOUNT_25',
        'DISCOUNT_35',
        'DISCOUNT_40',
        'DISCOUNT_50',
        'DISCOUNT_55',
        'DISCOUNT_FREE_UNIT'
      ],
      defaultValue: 'DISCOUNT_25',
      showIf: o =>
        !o.get('sendDiscountCode') &&
        ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'discountCode',
      type: 'string',
      defaultValue: '',
      showIf: o =>
        o.get('sendDiscountCode') &&
        ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'endpointApiSite',
      type: 'string',
      defaultValue: 'https://qa2-subscription.thepetlabco.uk',
      helperText: `Staging URL: https://qa2-subscription.thepetlabco.uk
      Live URL: https://subscription.altitudecheckout.com
      `,
      showIf: o => ['version2', 'version3'].includes(o.get('designVersion'))
    },
    {
      name: 'petlabCampaignTokenKey',
      type: 'string',
      defaultValue: 'petlab_campaign_token',
      showIf: o => ['version2', 'version3'].includes(o.get('designVersion')),
      helperText: 'Default: petlab_campaign_token'
    },
    {
      name: 'klaviyoUserIdKey',
      type: 'string',
      defaultValue: 'utm_id',
      showIf: o => ['version2', 'version3'].includes(o.get('designVersion')),
      helperText: 'Default: utm_id'
    },
    {
      name: 'klaviyoCampaignIdKey',
      type: 'string',
      defaultValue: 'utm_campaign',
      showIf: o => ['version2', 'version3'].includes(o.get('designVersion')),
      helperText: 'Default: utm_campaign'
    },
    {
      name: 'redirectIfAuthFails',
      type: 'boolean',
      defaultValue: false,
      showIf: o => ['version2', 'version3'].includes(o.get('designVersion'))
    },
    {
      name: 'topText',
      type: 'string',
      defaultValue: 'Add this to:',
      showIf: o => ['version2', 'version3'].includes(o.get('designVersion'))
    },
    {
      name: 'variantExistsMessage',
      type: 'richText',
      defaultValue: `Good news, you already have this product in your subscription! Please visit our <a href='[portal-url]'>Account Portal</a> if you would like to update the quantity of this product or to access even more bespoke offers.`,
      showIf: o => ['version2', 'version3'].includes(o.get('designVersion')),
      helperText: '[portal-url]'
    },
    {
      name: 'accountPortalUrl',
      type: 'string',
      defaultValue: 'https://account.thepetlabco.com/dashboard',
      showIf: o => ['version2', 'version3'].includes(o.get('designVersion')),
      helperText: 'Default: https://account.thepetlabco.com/dashboard'
    },
    {
      name: 'addLoadingCover',
      type: 'boolean',
      defaultValue: false,
      showIf: o => ['version2', 'version3'].includes(o.get('designVersion'))
    },
    {
      name: 'confirmationPopupPricingAddonOnly',
      type: 'boolean',
      defaultValue: false,
      showIf: o => ['version2', 'version3'].includes(o.get('designVersion'))
    },
    {
      name: 'successLayerId',
      type: 'string',
      defaultValue: 'success',
      showIf: o => ['version3'].includes(o.get('designVersion'))
    },
    {
      name: 'failedLayerId',
      type: 'string',
      defaultValue: 'failed',
      showIf: o => ['version3'].includes(o.get('designVersion'))
    },
    {
      name: 'subEngineProductId',
      type: 'string',
      showIf: o => ['version3'].includes(o.get('designVersion'))
    },
    {
      name: 'filterSku',
      type: 'string',
      showIf: o => ['version3'].includes(o.get('designVersion'))
    }
  ]
});
