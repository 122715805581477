import React, { useContext } from 'react';
import loadable from '@loadable/component';
import FunnelContext from '../../context/FunnelContext';

const ImageCarousel = props => {
  const { images, defaultTag, isFiltered = true } = props;
  const { currentProduct, extraObjects } = useContext(FunnelContext);

  const currentTag = extraObjects?.filterProductItemsByTag || defaultTag;
  const purchaseType = currentProduct ?? 'subscription_0';
  const filteredImages = isFiltered
    ? images
        .filter(image => image[purchaseType])
        .filter(image => {
          if (image?.filterByTag) {
            return image?.tag === currentTag;
          }
          return true;
        })
    : images;

  const ImageCarouselWrapper = loadable(
    () => import('./ImageCarouselWrapper'),
    {
      fallback: (
        <div>
          <img
            src={`${filteredImages[0].image}?format=webp`}
            alt={filteredImages[0].alt ? filteredImages[0].alt : `Carousel 0`}
            style={{ maxWidth: '390px', margin: '0 auto' }}
          />
        </div>
      )
    }
  );

  return <ImageCarouselWrapper {...props} images={filteredImages} />;
};

export default ImageCarousel;
