import { Builder } from '@builder.io/react';
import ProductTitle from './ProductTitle';

Builder.registerComponent(ProductTitle, {
  name: 'Product Title',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2'],
      defaultValue: 'version1'
    },
    {
      name: 'headTitle',
      type: 'string',
      defaultValue: 'Bestseller',
      showIf: options =>
        options.get('designVersion') === 'version2' &&
        options.get('showHeadTitle')
    },
    {
      name: 'name',
      type: 'string',
      defaultValue: 'Product Name'
    },
    {
      name: 'tubs',
      type: 'number',
      defaultValue: 30,
      showIf: options => options.get('designVersion') === 'version1'
    },
    {
      name: 'reviews',
      type: 'number',
      defaultValue: 3144,
      showIf: options => options.get('designVersion') === 'version1'
    },
    {
      name: 'otpTitle',
      type: 'string',
      defaultValue: '',
      showIf: options => options.get('designVersion') === 'version2'
    },
    {
      name: 'subsTitle',
      type: 'string',
      defaultValue: '',
      showIf: options => options.get('designVersion') === 'version2'
    },
    {
      name: 'showHeadTitle',
      type: 'boolean',
      defaultValue: true,
      advanced: true
    },
    {
      name: 'titleList',
      type: 'list',
      subFields: [
        {
          name: 'name',
          type: 'string',
          defaultValue: 'Product Name'
        },
        {
          name: 'hideSubTitle',
          type: 'boolean',
          defaultValue: false
        }
      ],
      advanced: true
    }
  ]
});
