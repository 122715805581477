import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as S from './NavLinksV1.styles';
import Modal from '../Modal/Modal';
import FunnelContext from '../../context/FunnelContext';

const NavLinks = props => {
  const {
    navLinks,
    textColor,
    textColorMobile,
    padding,
    paddingMob,
    marginTopMob,
    marginTopMobContainer = `72px`,
    iconPosition = 'right',
    iconTransform = 'noTransform'
  } = props;
  const { setNavClicked } = useContext(FunnelContext);
  const [showNav, setShowNav] = useState(false);

  const closeOnScroll = useCallback(() => {
    setShowNav(false);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', closeOnScroll);
    if (showNav) {
      document
        .getElementById('navlinks-wrapper')
        .addEventListener('touchstart', closeOnScroll);
    }
    return () => {
      window.removeEventListener('scroll', closeOnScroll);
    };
  }, [closeOnScroll, showNav]);

  const NavLinks = () => {
    const dLinks = navLinks || [
      {
        name: 'Benefits',
        link: '#NavBenefits',
        openInNewWindow: false
      },
      {
        name: 'Testimonials',
        link: '#NavTestimonials',
        openInNewWindow: false
      },
      {
        name: 'Ingredients',
        link: '#NavIngredients',
        openInNewWindow: false
      },
      {
        name: 'FAQ',
        link: '#NavFAQ',
        openInNewWindow: false
      }
    ];

    return (
      <S.NavLinks className={showNav ? 'active' : ''}>
        {dLinks.map(({ name, link, openInNewWindow }) => {
          return (
            <S.Link
              textColor={textColor}
              textColorMobile={textColorMobile}
              padding={padding}
              paddingMob={paddingMob}
              onClick={() => {
                setNavClicked(true);
                setShowNav(false);
              }}
              key={name}
              href={link}
              target={openInNewWindow ? '_blank' : ''}
            >
              {name}
            </S.Link>
          );
        })}
      </S.NavLinks>
    );
  };

  const triggerNav = e => {
    e.persist();
    setShowNav(!showNav);
  };

  return (
    <S.Container {...props.attributes}>
      <S.Nav>
        <NavLinks />
      </S.Nav>
      {showNav ? (
        <Modal>
          <S.NavWrapper
            onClick={closeOnScroll}
            id="navlinks-wrapper"
          ></S.NavWrapper>
          <S.NavMobile marginTopMobContainer={marginTopMobContainer}>
            <NavLinks />
          </S.NavMobile>
        </Modal>
      ) : null}
      <S.MobileNav
        marginTopMob={marginTopMob}
        textColor={textColor}
        iconPosition={iconPosition}
        iconTransform={iconTransform}
        onClick={triggerNav}
      >
        <S.BarLine className={showNav ? 'active-line1' : ''}></S.BarLine>
        <S.BarLine className={showNav ? 'active-line2' : ''}></S.BarLine>
        <S.BarLine className={showNav ? 'active-line3' : ''}></S.BarLine>
      </S.MobileNav>
    </S.Container>
  );
};

export default NavLinks;
