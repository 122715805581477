import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const TrustPilotPlugin = loadable(() => import('./TrustPilotPlugin'));

Builder.registerComponent(TrustPilotPlugin, {
  name: 'Trustpilot Plugin',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['version1', 'version2', 'version3'],
      defaultValue: 'version1'
    },
    {
      name: 'templateId',
      type: 'string',
      defaultValue: '539ad60defb9600b94d7df2c',
      showIf: o => ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'businessUnitId',
      type: 'string',
      defaultValue: '5c9df8263ff4c4000185ea05'
    },
    {
      name: 'height',
      type: 'string',
      defaultValue: '1850px',
      showIf: o => ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'width',
      type: 'string',
      defaultValue: '100%',
      showIf: o => ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'theme',
      type: 'string',
      enum: ['light', 'dark'],
      defaultValue: 'light',
      showIf: o => ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'tags',
      type: 'string',
      defaultValue: 'PBC',
      showIf: o => ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'stars',
      type: 'string',
      defaultValue: '4,5',
      showIf: o => ['version1', 'version3'].includes(o.get('designVersion'))
    },
    {
      name: 'locale',
      type: 'string',
      defaultValue: 'en-US',
      showIf: o => ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'languages',
      type: 'string',
      defaultValue: 'en',
      showIf: o => ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'isInnerSlide',
      type: 'boolean',
      defaultValue: false,
      advanced: true,
      showIf: o => ['version1'].includes(o.get('designVersion'))
    },
    {
      name: 'redirectUrl',
      type: 'string',
      defaultValue: 'https://www.trustpilot.com/review/thepetlabco.com',
      showIf: o => ['version2'].includes(o.get('designVersion'))
    },
    {
      name: 'score',
      type: 'string',
      defaultValue: '0.0',
      showIf: o => ['version2'].includes(o.get('designVersion'))
    },
    {
      name: 'reviews',
      type: 'string',
      defaultValue: '7000+',
      showIf: o => ['version2'].includes(o.get('designVersion'))
    },
    {
      name: 'reviewText',
      type: 'string',
      defaultValue: 'Excellent',
      showIf: o => ['version3'].includes(o.get('designVersion'))
    }
  ],
  noWrap: true
});
