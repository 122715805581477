import { Builder } from '@builder.io/react';
import loadable from '@loadable/component';

const ProductItem = loadable(() => import('./ProductItem'));

Builder.registerComponent(ProductItem, {
  name: 'Product Item',
  noWrap: true,
  image:
    'https://cdn.shopify.com/s/files/1/0015/4874/6807/files/Pet_lab_favicon-24_24x24.png',
  inputs: [
    {
      name: 'designVersion',
      type: 'string',
      enum: ['result', 'version1', 'version2'],
      defaultValue: 'version1'
    },
    {
      name: 'discountBadge',
      type: 'file',
      defaultValue: ''
    },
    {
      name: 'showFullPrice',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'maxQuantity',
      type: 'number',
      defaultValue: 4
    }
  ]
});
