import React, { useState } from 'react';
import * as S from './ToggleV1.styles';
import Modal from '../Modal/Modal';

function ToggleV1({ isModal = false, children }) {
  const [open, setOpen] = useState(false);

  const Content = ({
    isModal = false,
    children,
    modalWrapperBackgroundColor = 'rgba(204,204,204,0.5)'
  }) => {
    return (
      <>
        <S.Content modal={isModal}>
          {isModal && children && children[2] && (
            <S.Close onClick={() => setOpen(!open)}>
              {children && children[2]}
            </S.Close>
          )}
          {children && children[1]}
        </S.Content>
        {isModal && (
          <S.Wrapper
            onClick={() => setOpen(!open)}
            bgColor={modalWrapperBackgroundColor}
          ></S.Wrapper>
        )}
      </>
    );
  };

  return (
    <>
      <S.Trigger onClick={() => setOpen(!open)}>
        {(children && children[0]) || `no Trigger component, add first child`}
      </S.Trigger>
      {open && (
        <>
          {children && children[1] && (
            <>
              {!isModal && <Content isModal={false}>{children}</Content>}
              {isModal && (
                <Modal>
                  <Content isModal={true}>{children}</Content>
                </Modal>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default ToggleV1;
