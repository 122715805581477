import styled, { keyframes, css } from 'styled-components';

const slideInAnimation = keyframes`
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
`;

const slideOutAnimation = keyframes`
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
`;

export const Wrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9998;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 650px) {
    align-items: flex-end;
  }
`;

export const Container = styled.div`
  z-index: 9999;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 16px;
  position: relative;
  margin: auto;

  @media (max-width: 650px) {
    position: fixed;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    border-radius: 16px 16px 0 0;

    ${({ closing }) => {
      if (closing === null) {
        return ``;
      }
      return closing
        ? css`
            animation: ${slideOutAnimation} 0.5s ease-out forwards;
          `
        : css`
            animation: ${slideInAnimation} 0.5s ease-out forwards;
          `;
    }}
  }
`;

export const Body = styled.div`
  font-family: 'Neuzeit Grotesk', sans-serif;
  margin: 8px;
  border-radius: 0 0 8px 8px;
  padding: 14px 14px 0 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #001c72;
  background-color: #f2f4f8;

  @media (max-width: 650px) {
    padding-inline: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  margin-top: 9px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  font-family: 'Neuzeit Grotesk', sans-serif;

  @media (max-width: 950px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 650px) {
    margin-inline: 8px;
  }
`;
export const CloseButton = styled.div`
  color: #001c72;
  border-radius: 100%;
  cursor: pointer;
  font-size: 40px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  display: flex;
  align-items: center;
  & > span {
    display: block;
    margin-left: auto;
  }
  @media (max-width: 650px) {
    padding-right: 8px;
  }
`;

export const AcceptButton = styled.div`
  background: #cd0053;
  padding: 18px 57px;
  border-radius: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-left: 17px;

  @media (max-width: 950px) {
    width: 100%;
    margin-left: 0;
  }
`;
export const DeclineButton = styled.div`
  border-radius: 80px;
  width: 325px;
  border: solid 2px #cd0053;
  cursor: pointer;
  padding: 18px 77px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cd0053;

  @media (max-width: 950px) {
    width: 100%;
    margin-top: 12px;
  }
`;
