import React, { useState, useEffect } from 'react';
import * as S from './Timer.styles';

const Timer = ({ onTimerFinish, timerSeconds }) => {
  const [countdown, setCountdown] = useState(timerSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      clearInterval(interval);
      onTimerFinish();
    }

    return () => clearInterval(interval);
  }, [countdown, onTimerFinish]);

  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes
      .toString()
      .padStart(2, '0')} mins ${seconds.toString().padStart(2, '0')} secs`;
  };

  return (
    <S.TimerWrapper>
      <S.TimerTitle> Offer Expires in: </S.TimerTitle>
      <span> {formatTime(countdown)}</span>
    </S.TimerWrapper>
  );
};

export default Timer;
